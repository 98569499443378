'use client';
import { FeatureFlagContext } from '@unique/shared-library';
import { useNavigate } from 'react-router-dom';
import { useContext, useLayoutEffect } from 'react';
import DueDiligenceComponent from './DueDiligenceComponent';
import { useParams } from 'react-router-dom';

export default function DueDiligencePage() {
  const { flags } = useContext(FeatureFlagContext);
  const navigate = useNavigate();
  const { spaceId, spaceSlug } = useParams();

  useLayoutEffect(() => {
    if (!flags.FEATURE_FLAG_ENABLE_MAGIC_TABLE_UN_8472) {
      navigate('/');
    }
  }, []);

  return <DueDiligenceComponent spaceId={spaceId} spaceSlug={spaceSlug} />;
}
