import { MessageUpdateInput } from '@/@generated/graphql';
import { ButtonIcon, ButtonVariant, Textarea } from '@unique/component-library';
import { FC, useState } from 'react';
import { FeedbackCheckbox } from './FeedbackCheckbox';

interface Props {
  isPositive?: boolean;
  updateMessageData: (messageId: string, input: MessageUpdateInput) => void;
  handleClose: () => void;
  messageId: string;
}

export const FeedbackModal: FC<Props> = ({
  isPositive,
  updateMessageData,
  messageId,
  handleClose,
}) => {
  const [inaccurate, setInaccurate] = useState(false);
  const [missingInfo, setMissingInfo] = useState(false);
  const [notHelpful, setNotHelpful] = useState(false);
  const [outdated, setOutdated] = useState(false);
  const [emptyResponse, setEmptyResponse] = useState(false);
  const [text, setText] = useState('');

  const handleSubmit = () => {
    const input = {
      feedback: {
        create: {
          positive: isPositive,
          text,
          additionalInfo: JSON.stringify({
            inaccurate,
            missingInfo,
            notHelpful,
            outdated,
            emptyResponse,
          }),
        },
      },
    };

    updateMessageData(messageId, input);
    handleClose();
  };

  return (
    <div>
      <Textarea
        name="text"
        className="!text-on-background-main mb-4 !h-28 min-w-full sm:w-[570px]"
        placeholder={
          isPositive
            ? 'What do you like about the response?'
            : 'What was the issue with the response? How could it be improved?'
        }
        defaultValue={text}
        handleTextAreaChange={setText}
      />
      {!isPositive && (
        <div>
          <FeedbackCheckbox
            checked={inaccurate}
            setChecked={() => setInaccurate(!inaccurate)}
            id="inaccurate"
            text="Incorrect: entirely inaccurate or contains false information."
          />
          <FeedbackCheckbox
            checked={missingInfo}
            setChecked={() => setMissingInfo(!missingInfo)}
            id="missingInfo"
            text="Incomplete: critical details are missing."
          />
          <FeedbackCheckbox
            checked={notHelpful}
            setChecked={() => setNotHelpful(!notHelpful)}
            id="notHelpful"
            text="Irrelevant: contains information unrelated to my question."
          />
          <FeedbackCheckbox
            checked={outdated}
            setChecked={() => setOutdated(!outdated)}
            id="outdated"
            text="Outdated: contains information that is no longer accurate."
          />
          <FeedbackCheckbox
            checked={emptyResponse}
            setChecked={() => setEmptyResponse(!emptyResponse)}
            id="emptyResponse"
            text="No response was returned."
          />
        </div>
      )}
      <ButtonIcon variant={ButtonVariant.PRIMARY} className="float-right" onClick={handleSubmit}>
        Submit
      </ButtonIcon>
    </div>
  );
};
