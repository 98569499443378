'use client';

import { AssistantWithUserPrompts } from '@/lib/swr/types';
import { ButtonIcon, ButtonSize, ButtonVariant } from '@unique/component-library';
import {
  IconArrowDiagonal,
  IconArrowDown,
  IconBook,
  IconConfigure,
  IconLightBulb,
  IconNoPrompts,
} from '@unique/icons';
import { LayoutContext } from '@unique/shared-library';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { PromptsConfigurations } from './PromptsConfigurations';

type PromptSuggestionsProps = {
  assistant: AssistantWithUserPrompts;
  isChatInputEmpty: boolean;
  handleSelectPrompt?: (prompt: string) => void;
  isSending: boolean;
};

export const PromptSuggestions: FC<PromptSuggestionsProps> = ({
  handleSelectPrompt,
  assistant,
  isChatInputEmpty,
  isSending,
}) => {
  const { setSplitPaneContent, setIsMenuExpanded } = useContext(LayoutContext);
  const { user } = useAuth();
  const { spaceId, id: chatId } = useParams<{ id: string; spaceId: string }>();

  const [showMyPrompts, setShowMyPrompts] = useState<boolean>(false);
  const [showSuggestedPrompts, setShowSuggestedPrompts] = useState<boolean>(false);

  const onClickPrompt = (prompt: string) => {
    setShowSuggestedPrompts(false);
    setShowMyPrompts(false);
    typeof handleSelectPrompt === 'function' && handleSelectPrompt(prompt);
  };

  const onShowMyPromptsClick = () => {
    showSuggestedPrompts && setShowSuggestedPrompts(false);
    setShowMyPrompts(!showMyPrompts);
  };

  const onShowSuggestedPromptsClick = () => {
    showMyPrompts && setShowMyPrompts(false);
    setShowSuggestedPrompts(!showSuggestedPrompts);
  };

  const handleCloseConfigurations = useCallback(() => {
    setSplitPaneContent(null);
  }, [setSplitPaneContent]);

  const mySuggestions =
    assistant?.userPrompts?.filter((userPrompt) => userPrompt.ownedBy === user.profile.sub) || [];
  const promptSuggestions =
    assistant?.userPrompts?.filter((userPrompt) => !userPrompt.ownedBy) || [];

  const showConfigurations = () => {
    setSplitPaneContent(
      <PromptsConfigurations
        handleCloseConfigurations={handleCloseConfigurations}
        assistantId={assistant?.id}
      />,
    );
    setIsMenuExpanded(false);
  };

  useEffect(() => {
    if (isSending || !isChatInputEmpty) {
      setShowMyPrompts(false);
      setShowSuggestedPrompts(false);
      return;
    }

    if (showMyPrompts && mySuggestions.length > 0) {
      setShowMyPrompts(!chatId);
      setShowSuggestedPrompts(false);
    } else {
      setShowMyPrompts(false);
      if (promptSuggestions.length > 0 && showSuggestedPrompts) {
        setShowSuggestedPrompts(!chatId);
      } else {
        setShowSuggestedPrompts(false);
      }
    }
  }, [
    assistant,
    mySuggestions.length,
    promptSuggestions.length,
    spaceId,
    isChatInputEmpty,
    isSending,
  ]);

  const suggestionsToShow = showMyPrompts ? mySuggestions : promptSuggestions;

  return (
    <div className="relative mb-4 mt-2 w-full">
      <ButtonIcon
        className={cn({
          '!bg-background !text-on-background-dimmed hover:!bg-background-variant hover:!text-on-background-main':
            !showMyPrompts,
          'mr-4': true,
        })}
        icon={<IconBook />}
        onClick={onShowMyPromptsClick}
      >
        <span className="flex items-center gap-x-3">
          <span className="hidden sm:inline">My Prompts </span>
          <IconArrowDown
            className={cn({
              'transition duration-200': true,
              'rotate-180': showMyPrompts,
            })}
            width="14px"
            height="14px"
          />
        </span>
      </ButtonIcon>
      {promptSuggestions.length > 0 && (
        <ButtonIcon
          className={cn({
            '!bg-background !text-on-background-dimmed hover:!bg-background-variant hover:!text-on-background-main':
              !showSuggestedPrompts,
          })}
          icon={<IconLightBulb />}
          onClick={onShowSuggestedPromptsClick}
        >
          <span className="flex items-center gap-x-3">
            <span className="hidden sm:inline">Suggestions </span>
            <IconArrowDown
              className={cn({
                'transition duration-200': true,
                'rotate-180': showSuggestedPrompts,
              })}
              width="14px"
              height="14px"
            />
          </span>
        </ButtonIcon>
      )}
      <div
        className={cn({
          'absolute bottom-10 mb-3 w-[calc(100vw-32px)] md:w-full': true,
          hidden: !showSuggestedPrompts && !showMyPrompts,
        })}
      >
        {showMyPrompts && (
          <ButtonIcon
            className="ml-auto mr-4 !flex !rounded-[0px] !rounded-t-md"
            icon={<IconConfigure />}
            onClick={showConfigurations}
            variant={ButtonVariant.SECONDARY}
            buttonSize={ButtonSize.SMALL}
          >
            Configure
          </ButtonIcon>
        )}
        <div
          className={cn({
            'border-background-variant bg-surface max-h-[50vw] w-[calc(100vw-32px)] overflow-y-auto rounded-[8px] border md:max-h-[calc(100vh-320px)] md:w-full':
              true,
            hidden: !showSuggestedPrompts && !showMyPrompts,
          })}
        >
          {suggestionsToShow.length > 0 ? (
            <>
              {suggestionsToShow.map((suggestion) => {
                return (
                  <div
                    key={suggestion.id || new Date().getTime()}
                    className="subtitle-2 text-on-surface hover:bg-background group flex max-h-[300px] cursor-pointer items-center justify-between gap-x-4 px-4 py-3.5 font-bold transition"
                    onClick={() => onClickPrompt(suggestion.prompt)}
                  >
                    {suggestion.title}
                    <div className="text-control group-hover:text-on-background-dimmed flex items-center justify-center transition">
                      <IconArrowDiagonal />
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {showMyPrompts && (
                <div className="body-2 text-on-background-dimmed m-auto flex flex-col items-center p-10 text-center">
                  <IconNoPrompts className="mb-2" />
                  Create a library of your <br /> most used prompts.
                  <ButtonIcon
                    variant={ButtonVariant.SECONDARY}
                    onClick={showConfigurations}
                    className="mt-2"
                  >
                    Get Started
                  </ButtonIcon>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
