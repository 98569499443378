import { ContentByIdQuery, Maybe } from '@/@generated/graphql';
import { Reference } from '@/lib/swr/types';

export const INTERNAL_URL_PREFIX = 'unique://content/';

export const isInternalUniqueUrl = (url: Maybe<string> | undefined) =>
  url && url.startsWith(INTERNAL_URL_PREFIX);

export const parseContentIdFromInternalUrl = (url: Maybe<string> | undefined) => {
  const urlParts = url?.split('/');
  return urlParts?.length > 3 ? urlParts[3] : '';
};

export const mapReadUrlForReference = (
  reference: Reference,
  contentData: ContentByIdQuery,
  redirectInternalStorageOnly: boolean,
) => {
  if (!reference) return;
  if (isInternalUniqueUrl(reference.url || '')) {
    const contentId = parseContentIdFromInternalUrl(reference.url || '');
    const content = contentData?.contentById?.find((content) => content.id === contentId);
    if (content) {
      return {
        ...reference,
        url: redirectInternalStorageOnly
          ? `${INTERNAL_URL_PREFIX}${encodeURIComponent(content.id)}`
          : content.url ?? `${INTERNAL_URL_PREFIX}${encodeURIComponent(content.id)}`,
        pdfPreviewName: content.previewPdfFileName,
        contentId: content.id,
        mimeType: content.mimeType,
      };
    }
  }
  return reference;
};

export const mapReadUrlForReferences = (
  references: Reference[],
  contentData: ContentByIdQuery,
  redirectInternalStorageOnly: boolean,
) => {
  return references.map((reference) =>
    mapReadUrlForReference(
      reference,
      contentData,
      redirectInternalStorageOnly,
    ),
  );
};
