'use client';
import { FC, useContext, useState } from 'react';
import { ExploreSpaceButton, ExploreSpaceButtonVariant } from './ExploreSpaceButton';
import { LayoutContext, useScreens } from '@unique/shared-library';
import cn from 'classnames';
import {
  ButtonIcon,
  ButtonSize,
  ButtonVariant,
  Drawer,
  DrawerProvider,
  MenuItem,
} from '@unique/component-library';
import { IconMeatballsMenu, IconSpace } from '@unique/icons';
import { useNavigate } from 'react-router-dom';

type SpaceHeaderProps = {
  title: string;
};

export const SpaceHeader: FC<SpaceHeaderProps> = ({ title }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { isMenuExpanded } = useContext(LayoutContext);
  const { isDesktop } = useScreens();
  const navigate = useNavigate();

  const handleClickExploreSpaces = () => {
    navigate('/space/explore');
  };

  return (
    <DrawerProvider>
      <div className="flex min-w-0 flex-1 items-center">
        <div
          className={cn({
            'text-on-surface relative my-4 flex-1 shrink-0 gap-x-4 self-center truncate whitespace-nowrap px-5 font-bold':
              true,
            'subtitle-1': !isDesktop,
            'title-s': isDesktop,
          })}
        >
          {title}
        </div>
        {/* meatballs menu including chat delete action is only visible on mobile */}
        <div className="ml-auto pr-1.5 sm:hidden">
          <ButtonIcon
            icon={<IconMeatballsMenu />}
            variant={ButtonVariant.SECONDARY}
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              setIsDrawerOpen(true);
            }}
          />
        </div>
      </div>
      <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <div className="mt-8">
          <MenuItem
            icon={<IconSpace />}
            title="Explore Spaces"
            handleClick={handleClickExploreSpaces}
          />
        </div>
      </Drawer>
      {/* explore space button is only visible on desktop & tablet when navigation is collapsed */}
      <div
        className={cn({
          'ml-auto hidden self-center overflow-hidden whitespace-nowrap pr-4 transition-opacity delay-200 duration-200 ease-in-out sm:block':
            true,
          'w-auto opacity-100': !isMenuExpanded,
          'pointer-events-none w-0 opacity-0': isMenuExpanded,
        })}
      >
        <div className="overflow-hidden">
          <ExploreSpaceButton variant={ExploreSpaceButtonVariant.DARK} onClick={() => {
            navigate('/space/explore');
          }} />
        </div>
      </div>
    </DrawerProvider>
  );
};
