import { UITypes } from './userInterface';

export const hasTranslationUI = (object: {
  settings?: Record<string, string | number | Record<string, string>>;
}) => {
  return object?.settings?.userInterface === UITypes.TRANSLATION;
};

export const renderUI = (object: {
  settings?: Record<string, string | number | Record<string, string>>;
}) => {
  if (object?.settings?.userInterface === UITypes.TRANSLATION) {
    return UITypes.TRANSLATION;
  }
  if (object?.settings?.userInterface === UITypes.MAGIC_TABLE) {
    return UITypes.MAGIC_TABLE;
  }
  return UITypes.CHAT;
};
