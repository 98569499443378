'use client';
import { useAppDispatch, useAppSelector } from '@/store';
import { updateEditingCell } from '@/store/slices/dueDiligence';
import { ButtonIcon, ButtonVariant, Textarea } from '@unique/component-library';
import {
  IconClose,
  IconDownloadLine,
  IconFileAdd,
  IconPaperPlane,
  IconViewFolders,
} from '@unique/icons';
import cn from 'classnames';
import DueDiligenceInputUploadButton from './DueDiligenceInputUploadButton';
import { FolderPathSelection } from '@unique/shared-library';
import { useState } from 'react';
import { useAddMagicTableDocumentsMutation } from '@/lib/swr/hooks';
import { AddMagicTableDocumentsDocument } from '@/@generated/graphql';
import Dropdown, { DropdownOptions } from '@unique/component-library/src/Dropdown';
import { magicTableColumns } from './tableColumns';

const csvFileName = `magic-table-${new Date().toISOString().split('T')[0]}`;

type Props = {
  getTableData: (fileName: string) => void;
  stopEditingCell: () => void;
  sheetId: string;
  updateCell: (data: {
    columnOrder: number;
    rowOrder: number;
    data: string;
    sheetId: string;
  }) => void;
  getLastOccupiedColumn: () => number;
};

const transformQuestions = (
  questions: { id: string; title: string; question: string }[],
): DropdownOptions[] => {
  return questions.map((question) => ({
    id: question.id,
    title: question.title,
    value: question.question,
  }));
};

export default function DueDiligenceButtons({
  getTableData,
  stopEditingCell,
  sheetId,
  updateCell,
  getLastOccupiedColumn,
}: Props) {
  const dueDiligence = useAppSelector((state) => state.dueDiligence);
  const dispatch = useAppDispatch();

  const { trigger: addMagicTable } = useAddMagicTableDocumentsMutation({
    query: AddMagicTableDocumentsDocument,
  });

  const [showFolderPathSelectionModal, setShowFolderPathSelectionModal] = useState(false);

  const clearEditingSelection = () => {
    stopEditingCell();
    dispatch(updateEditingCell(null));
  };

  const classNames = cn({
    relative: true,
    'pl-24': dueDiligence.editingCell,
  });

  const handleFolderPathSelected = ({ folderIds }) => {
    setShowFolderPathSelectionModal(false);
    const contentIds = folderIds.filter((id) => id.startsWith('cont_'));

    addMagicTable({ contentIds, sheetId });
  };

  const onColumnSelect = (option: DropdownOptions) => {
    updateCell({
      columnOrder: getLastOccupiedColumn(),
      rowOrder: 1,
      data: option.value,
      sheetId,
    });
  };

  return (
    <div className="mx-5 my-5 max-w-3xl">
      <form className="w-full">
        <div className="relative flex w-full justify-between">
          {dueDiligence.editingCell && (
            <button
              className="bg-background text-on-background-dimmed shadow-background absolute left-2 z-10 my-auto mt-1 flex h-5 w-auto items-center justify-center rounded-md px-2 py-4 text-sm font-bold shadow-sm"
              onClick={clearEditingSelection}
            >
              Cell&nbsp;{`${dueDiligence.editingCell?.columnId}${dueDiligence?.editingCell.rowId}`}
              &nbsp;
              <IconClose />
            </button>
          )}
          <Textarea name="text" placeholder="Ask your table anything" className={classNames} />
          <ButtonIcon
            variant={ButtonVariant.PRIMARY}
            className="absolute right-9 top-0 my-auto mb-2.5 h-8 w-8 border-none"
            icon={<IconPaperPlane />}
          />
        </div>
      </form>
      <div className="mt-3 flex flex-wrap gap-3">
        <ButtonIcon
          variant={ButtonVariant.SECONDARY}
          icon={<IconFileAdd />}
          onClick={() => setShowFolderPathSelectionModal(true)}
        >
          Add Files
        </ButtonIcon>
        <Dropdown
          dropdownOptions={transformQuestions(magicTableColumns)}
          onSelect={(option) => onColumnSelect(option)}
          className="w-[300px]"
        >
          <ButtonIcon
            variant={ButtonVariant.SECONDARY}
            icon={<IconViewFolders />}
            className="justify-start text-nowrap"
          >
            Add Columns
          </ButtonIcon>
        </Dropdown>
        <DueDiligenceInputUploadButton />
        <ButtonIcon
          onClick={() => {
            getTableData(csvFileName);
          }}
          variant={ButtonVariant.SECONDARY}
          icon={<IconDownloadLine />}
        >
          Download
        </ButtonIcon>
      </div>
      {showFolderPathSelectionModal && (
        <FolderPathSelection
          handleClose={() => setShowFolderPathSelectionModal(false)}
          handleSubmit={handleFolderPathSelected}
          submitButtonText="Add files"
          isSelectable
          modalTitle="Select Files or Folders to add"
        />
      )}
    </div>
  );
}
