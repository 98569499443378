import { ButtonIcon, ButtonSize, ButtonVariant } from '@unique/component-library';
import { IconThumbsDown, IconThumbsUp } from '@unique/icons';
import { Feedback } from '@/@generated/graphql';

type Props = {
  onThumbsClick: (id: string, isPositive?: boolean) => void;
  feedback: Feedback | { positive: boolean };
  id: string;
};

const FeedbackButtons = ({ feedback, onThumbsClick, id }: Props) => {
  return (
    <div className="flex items-center">
      {typeof feedback?.positive !== 'undefined' ? (
        <div>
          {feedback.positive ? (
            <IconThumbsUp className="text-success-dark" height="24" width="21" />
          ) : (
            <IconThumbsDown className="text-error-dark" height="24" width="21" />
          )}
        </div>
      ) : (
        <div className="flex gap-x-4">
          <ButtonIcon
            onClick={() => onThumbsClick(id, true)}
            variant={ButtonVariant.SECONDARY}
            buttonSize={ButtonSize.SMALL}
            icon={<IconThumbsUp />}
          />
          <ButtonIcon
            onClick={() => onThumbsClick(id, false)}
            variant={ButtonVariant.SECONDARY}
            buttonSize={ButtonSize.SMALL}
            icon={<IconThumbsDown />}
          />
        </div>
      )}
    </div>
  );
};

export default FeedbackButtons;
