import { format } from 'date-fns';
import { ButtonIcon, ButtonVariant, MarkdownPreview } from '@unique/component-library';
import Skeleton from '@unique/component-library/src/Skeleton';
import { IconDelete, IconDownload, IconCaret } from '@unique/icons';
import { AnalyticsOrder, AnalyticsOrderState } from '@/@generated/graphql';
import { getAnalyticsOrderStateText } from '@/helpers/analyticsStatus';

export const getAnalyticsTableRow = (
  analyticsOrder: AnalyticsOrder,
  startOrder: (id: string) => void,
  deleteOrder: (id: string) => void,
  downloadOrder: (id: string) => void,
  hasRunningOrder: boolean,
) => ({
  created_at: <div>{format(new Date(analyticsOrder.createdAt), 'PPpp')}</div>,
  order_id: <MarkdownPreview text={analyticsOrder.id} />,
  analytics_type: <div>{analyticsOrder.type}</div>,
  state: <div>{getAnalyticsOrderStateText(analyticsOrder.state)}</div>,
  actions: (
    <div>
      {!hasRunningOrder &&
        (analyticsOrder.state === AnalyticsOrderState.Error ||
          analyticsOrder.state === AnalyticsOrderState.Created) && (
          <ButtonIcon
            icon={<IconCaret />}
            variant={ButtonVariant.PRIMARY}
            className="mt-4 w-full"
            onClick={() => startOrder(analyticsOrder.id)}
          >
            Start
          </ButtonIcon>
        )}
      {analyticsOrder.state !== AnalyticsOrderState.Running && (
        <ButtonIcon
          icon={<IconDelete />}
          variant={ButtonVariant.DANGER}
          className="mt-4 w-full"
          onClick={() => deleteOrder(analyticsOrder.id)}
        >
          Delete
        </ButtonIcon>
      )}
      {analyticsOrder.state == AnalyticsOrderState.Done && (
        <ButtonIcon
          icon={<IconDownload />}
          variant={ButtonVariant.PRIMARY}
          className="mt-4 w-full"
          onClick={() => downloadOrder(analyticsOrder.id)}
        >
          Download
        </ButtonIcon>
      )}
    </div>
  ),
});

export const getAnalyticsTableSkeletonRow = () =>
  Array(10).fill({
    created_at: <Skeleton className="!w-25 !h-5" />,
    order_id: <Skeleton className="!w-25 !h-5" />,
    analytics_type: <Skeleton className="!w-25 !h-5" />,
    state: <Skeleton className="!w-15 !h-5" />,
    actions: <Skeleton className="!h-5 !w-10" />,
  });
