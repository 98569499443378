import { FC } from 'react';
import { ButtonIcon, ButtonVariant } from '@unique/component-library';
import { IconMenu } from '@unique/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { RowData } from './DueDiligenceList';

type Props = {
  headerTitle: string;
  rowData: RowData[];
};

const DueDiligenceHistoryTable: FC<Props> = ({ headerTitle = 'Yesterday', rowData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full px-2 py-2 align-middle">
          <table className="min-w-full table-fixed divide-y divide-transparent">
            <thead>
              <tr className="text-on-background-dimmed uppercase">
                <th
                  scope="col"
                  className="w-[33%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 lg:pl-8"
                >
                  {headerTitle}
                </th>
                <th scope="col" className="w-[33%] px-3 py-3.5 text-left text-sm font-semibold">
                  Last Edited
                </th>
                <th
                  scope="col"
                  className="w-[33%] px-3 py-3.5 text-left text-sm font-semibold"
                ></th>
              </tr>
            </thead>
            <tbody className="divide-background-variant text-on-background-main bg-surface divide-y">
              {rowData.map((data) => (
                <tr
                  key={data.id}
                  onClick={() => navigate(`${pathname}/${data.id}`)}
                  className="hover:bg-background cursor-pointer font-medium"
                >
                  <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6 lg:pl-8">
                    {data.name}
                  </td>
                  <td className="whitespace-nowrap px-3 py-2 text-sm">{data.lastEdited}</td>
                  <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm sm:pr-6 lg:pr-8">
                    <ButtonIcon icon={<IconMenu />} variant={ButtonVariant.SECONDARY} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DueDiligenceHistoryTable;
