'use client';

import { FC, useState } from 'react';
import { IconRocket } from '@unique/icons/IconRocket';
import { ButtonIcon } from '@unique/component-library';
import { IconDownload } from '@unique/icons';
import { downloadFile, ToastVariant, useToast } from '@unique/shared-library';
import { useAuth } from 'react-oidc-context';
import { logger } from '@unique/next-commons/logger';

type Props = {
  chatBackendUrl: string;
};

const benchmarkLogger = logger.child({ module: 'ChatBenchmarking' });

export const ExcelTemplateDownload: FC<Props> = ({ chatBackendUrl }) => {
  const [downloadingTemplate, setDownloadingTemplate] = useState(false);
  const auth = useAuth();
  const { showToast } = useToast();

  const handleDownload = async () => {
    try {
      setDownloadingTemplate(true);
      const response = await fetch(chatBackendUrl + '/benchmarking/action/template-download', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth?.user.access_token}`,
        },
      });
      if (!response.ok) {
        benchmarkLogger.error(
          `Error while downloading the file: ${response.statusText} body: ${await response.text()}`,
        );
        throw new Error(`Error while downloading the file: ${response.statusText}`);
      }
      response.blob().then((blob) => {
        // Download Excel
        downloadFile(blob, 'benchmark_template.xlsx');
        showToast({
          message: 'File downloaded successfully',
          variant: ToastVariant.SUCCESS,
        });
      });
    } catch (err) {
      showToast({
        message: 'There was an error while downloading the file',
        variant: ToastVariant.ERROR,
      });
    } finally {
      setDownloadingTemplate(false);
    }
  };

  return (
    <div className="bg-surface border-background-variant flex items-center justify-between rounded-lg border px-5 py-6">
      <IconRocket />
      <div className="flex flex-col gap-1">
        <p className="text-on-background-main font-bold">Just getting started?</p>
        <p className="text-on-background-main font-medium">
          Download our template to ensure your Excel File is correctly formatted for the
          benchmarking process.
        </p>
      </div>
      <ButtonIcon isLoading={downloadingTemplate} icon={<IconDownload />} onClick={handleDownload}>
        Download Template
      </ButtonIcon>
    </div>
  );
};
