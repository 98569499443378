import { ButtonIcon, ButtonVariant } from '@unique/component-library';
import { IconExpiredFile } from '@unique/icons';
import { getFileTypeVisuals } from '@unique/shared-library';
import { FC, ReactNode } from 'react';

export const PROMPT_URL_PREFIX_HTTPS = 'https://prompt=';
export const PROMPT_URL_PREFIX_HTTP = 'http://prompt=';

const getFileTypeIcon = (children: ReactNode[] | string) => {
  if (!Array.isArray(children) && typeof children !== 'string') return null;
  let fileNameParts = [];
  if (typeof children === 'string') {
    fileNameParts = children.split('.');
  }

  if (Array.isArray(children) && typeof children[0] === 'string') {
    fileNameParts = children.length && children[0].split('.');
  }

  // If the file name doesn't have a file type, return null
  if (fileNameParts.length < 2) return null;
  const fileType = fileNameParts[fileNameParts.length - 1];
  return getFileTypeVisuals(fileType, false);
};

const extractPrompt = (href: unknown) => {
  if (typeof href !== 'string') return '';
  const prompt = href.replace(PROMPT_URL_PREFIX_HTTPS, '').replace(PROMPT_URL_PREFIX_HTTP, '');
  return decodeURIComponent(prompt);
};

type ChatReferenceButtonProps = {
  handleSelectPrompt?: (prompt: string) => void;
  href?: unknown;
  children?: ReactNode[];
  isExpired?: boolean;
};

export const ChatReferenceButton: FC<ChatReferenceButtonProps> = ({
  handleSelectPrompt,
  href,
  children,
  isExpired,
}) => {
  const FileTypeIcon = getFileTypeIcon(children);
  const prompt = extractPrompt(href);

  const getIcon = () => {
    if (isExpired) {
      return <IconExpiredFile width="18px" height="18px" />;
    }
    return FileTypeIcon ? <FileTypeIcon width="18px" height="18px" /> : null;
  };
  return (
    <ButtonIcon
      variant={ButtonVariant.SECONDARY}
      onClick={() => handleSelectPrompt && handleSelectPrompt(prompt)}
      icon={getIcon()}
      className={isExpired ? 'pointer-events-none' : ''}
    >
      {children}
    </ButtonIcon>
  );
};
