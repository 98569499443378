import {
  getUseAssistantsWithUserPromptsQueryKey,
  useAssistantQuery,
  useUserPromptCreateMutation,
} from '@/lib/swr/hooks';
import { ButtonIcon, ButtonVariant, Textarea } from '@unique/component-library';
import { ToastVariant, useToast } from '@unique/shared-library';
import { FC, useState } from 'react';
import { useAuth } from 'react-oidc-context';

interface Props {
  assistantId: string;
  prompt: string;
  handleClose: () => void;
}

export const SavePromptModal: FC<Props> = ({ assistantId, prompt, handleClose }) => {
  const { user } = useAuth();
  const { showToast } = useToast();
  const [title, setTitle] = useState('');

  const { mutate: mutateAssistant } = useAssistantQuery(
    assistantId ? { assistantId: assistantId } : null,
    { revalidateOnFocus: false },
  );
  const key = getUseAssistantsWithUserPromptsQueryKey();
  const { trigger: createUserPrompt } = useUserPromptCreateMutation(key);

  const handleAdd = () => {
    const input = {
      ownedBy: user.profile.sub,
      prompt,
      title: title,
    };

    createUserPrompt(
      { assistantId, input },
      {
        onSuccess: () => {
          showToast({
            message: `${title} added to My Prompts`,
            variant: ToastVariant.SUCCESS,
          });
          mutateAssistant();
        },
      },
    );
    handleClose();
  };

  return (
    <div>
      <Textarea
        name="text"
        className="bg-surface !text-on-surface mb-4 !h-10 min-w-full sm:w-[570px]"
        placeholder="Add prompt title *"
        defaultValue={title}
        handleTextAreaChange={setTitle}
      />
      <Textarea
        name="text"
        className="bg-surface !text-on-surface mb-4 !h-28 min-w-full sm:w-[570px]"
        defaultValue={prompt}
        disabled={true}
      />
      <ButtonIcon
        variant={ButtonVariant.PRIMARY}
        className="float-right"
        onClick={handleAdd}
        disabled={!title}
      >
        Add
      </ButtonIcon>
    </div>
  );
};
