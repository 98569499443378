import { useMagicTableQuery } from '@/lib/swr/hooks';
import { logger } from '@unique/next-commons/logger';

const log = logger.child({
  package: 'chat',
  namespace: 'components:magic-table-component:useMagicTableQuerySubscription',
});

type Props = { sheetId: string };

export default function useDueDiligenceQuery({ sheetId }: Props) {
  const {
    data,
    isLoading: loadingTableData,
    mutate,
  } = useMagicTableQuery(
    { cursor: { id: sheetId } },
    {
      onError: () => {
        log.error('Unable to fetch Magic Table Data');
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    },
  );

  const requeryDueDiligence = () => {
    mutate();
  };

  const dueDiligenceData = data?.getTransformedMagicTableSheet?.magicTableSheet;

  return { dueDiligenceData, loadingTableData, requeryDueDiligence } as const;
}
