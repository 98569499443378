import { fileTypeFromStream } from 'file-type';
import { logger } from '../logger';

const log = logger.child({
  package: 'next-commons',
  namespace: 'helpers:loadFile',
});

type LoadFileInfo = {
  id: string;
  mimeType?: string;
  title?: string;
  key?: string;
  url?: string;
  readUrl?: string;
  internallyStoredAt?: string;
};

const getFileFromApi = async (
  url: string,
  accessToken: string,
  mimeType: string,
  title: string,
  shouldOpen?: boolean,
) => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${accessToken}`);

  const response = await fetch(url, { headers });
  const file = await response.blob();
  const objectUrl = window.URL.createObjectURL(file);

  if (shouldOpen) {
    // preventing XSS - invalid modification of file type
    if (mimeType !== file.type) {
      // If file.type is wrong might be our blob not super accurate,
      // we then ALSO try to define the mimetype from stream directly
      const mimeTypeFromResponse = await fileTypeFromStream(file.stream());
      log.info(
        `File type mismatch: ${mimeType} !== ${file.type}, identified from stream as ${mimeTypeFromResponse.mime}`,
      );
      if (mimeType !== mimeTypeFromResponse.mime) {
        throw new Error(`File type mismatch`);
      }
    }

    if (mimeType === 'application/pdf' || mimeType === 'text/plain') {
      window.open(objectUrl, '_blank');
    } else {
      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.href = objectUrl;
      anchor.download = title;
      anchor.click();
    }
  }
  return objectUrl;
};

export const loadFile = async ({
  accessToken,
  ingestionUrl,
  content,
  chatId,
  shouldOpen,
}: {
  accessToken: string;
  ingestionUrl: string;
  content: LoadFileInfo;
  chatId?: string;
  shouldOpen?: boolean;
}) => {
  // If the content is not stored internally, open the file in a new tab if readUrl or url is present
  if (!content.internallyStoredAt) {
    if (content.url || content.readUrl) {
      window.open(content.url ?? content.readUrl, '_blank', 'noopener noreferrer');
    }
    return;
  }
  let requestUrl = `${ingestionUrl}/v1/content/${encodeURIComponent(content.id)}/file`;
  if (chatId) {
    requestUrl += `?chatId=${encodeURIComponent(chatId)}`;
  }

  return await getFileFromApi(
    requestUrl,
    accessToken,
    content.mimeType,
    content.title ?? content.key ?? content.id,
    shouldOpen,
  );
};
