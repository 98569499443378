'use client';

import { SortOrder } from '@/@generated/graphql';
import { useAssistantsWithUserPromptsQuery } from '@/lib/swr/hooks';
import { AssistantWithUserPrompts } from '@/lib/swr/types';
import { Drawer, DrawerProvider, Spinner } from '@unique/component-library';
import { LayoutContext, sortAssistants, useScreens } from '@unique/shared-library';
import cn from 'classnames';
import { FC, useContext, useEffect, useState } from 'react';
import { PoweredByDisclaimer } from '../PoweredByDisclaimer';
import { ExploreSpaceInfo } from './ExploreSpaceInfo';
import { ExploreSpaceListItem } from './ExploreSpaceListItem';

export const ExploreSpacePage: FC = () => {
  const [selectedAssistant, setSelectedAssistant] = useState<AssistantWithUserPrompts | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { setHeaderItems, setSplitPaneContent } = useContext(LayoutContext);
  const { isMobile } = useScreens();

  const { data: assistantsQueryResult, isLoading: isLoadingAssistants } =
    useAssistantsWithUserPromptsQuery({
      orderBy: [{ name: SortOrder.Asc }],
    });

  useEffect(() => {
    if (!assistantsQueryResult?.assistants) return;
    if (!isMobile) {
      setSelectedAssistant(assistantsQueryResult.assistants[0]);
    }
  }, [assistantsQueryResult?.assistants, isMobile]);

  useEffect(() => {
    setSplitPaneContent(null);
  }, [setSplitPaneContent]);

  useEffect(() => {
    setHeaderItems([
      <div key="explore-space-title" className="flex items-center px-3.5 py-4 sm:px-6">
        <span
          className={cn({
            'text-on-surface font-bold': true,
            'subtitle-1': isMobile,
            'title-s': !isMobile,
          })}
        >
          Explore Spaces
        </span>
      </div>,
    ]);
    return () => {
      setHeaderItems([]);
    };
  }, [setHeaderItems, isMobile]);

  if (isLoadingAssistants) {
    return <Spinner wrapperClasses="w-full h-full flex items-center justify-center" />;
  }

  return (
    <DrawerProvider>
      <div className="mx-auto flex h-[calc(100vh-119px)] max-w-[928px] flex-col sm:h-[calc(100vh-81px)]">
        <div className="mt-4 flex w-full flex-col items-center sm:flex-row sm:flex-wrap md:mt-10 md:gap-6 md:px-6 lg:mt-[80px] xl:justify-center xl:px-0">
          <div className="mb-5 h-auto self-start px-3.5 sm:mb-10 sm:w-full sm:px-6 md:w-auto md:flex-1 md:px-0 lg:mb-0">
            <h2
              className={cn({
                'text-primary-cta mb-3 font-bold': true,
                'subtitle-1': isMobile,
                'title-s': !isMobile,
              })}
            >
              Start here <span className="hidden px-1 md:inline">&#8594;</span>
            </h2>
            <p className="body-2 text-on-surface">
              Browse your Spaces and click New Chat to input your requests in the right Space.
            </p>
          </div>
          <div className="global-scrollbar scrollbar-gutter-stable md:border-l-background-variant relative w-full self-start overflow-hidden px-3.5 hover:overflow-y-auto sm:max-h-[calc(100vh-280px)] sm:w-auto sm:flex-1 sm:px-6 md:max-h-[calc(100vh-230px)] md:border-l md:pl-6 md:pr-2 lg:pr-4">
            {sortAssistants(assistantsQueryResult?.assistants)?.map((assistant) => (
              <div key={assistant.id} className="mb-2">
                <ExploreSpaceListItem
                  assistant={assistant}
                  isActive={selectedAssistant?.id === assistant.id}
                  handleClickAssistant={() => {
                    setIsDrawerOpen(isMobile);
                    setSelectedAssistant(assistant);
                  }}
                />
              </div>
            ))}
          </div>
          <div className="global-scrollbar scrollbar-gutter-stable hidden flex-1 self-start overflow-hidden px-3.5 hover:overflow-y-auto sm:block sm:max-h-[calc(100vh-280px)] sm:px-6 md:max-h-[calc(100vh-230px)] md:px-0">
            <ExploreSpaceInfo assistant={selectedAssistant} />
          </div>
        </div>
        <div className="mb-2 mt-auto">
          <PoweredByDisclaimer />
        </div>
      </div>
      <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <div className="global-scrollbar overflow-y-auto p-4">
          <ExploreSpaceInfo assistant={selectedAssistant} />
        </div>
      </Drawer>
    </DrawerProvider>
  );
};
