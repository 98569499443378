import { useChatDelete } from '@/hooks/useChatDelete';
import { useChatUpdateTitle } from '@/hooks/useChatUpdateTitle';
import { CHAT_TITLE_MAX_LENGTH } from '@/lib/constants/chatTitleMaxLength';
import { useChatQuery } from '@/lib/swr/hooks';
import {
  ButtonIcon,
  ButtonSize,
  ButtonVariant,
  Drawer,
  DRAWER_POSITION,
  DrawerProvider,
  InputField,
  MenuItem,
} from '@unique/component-library';
import { IconDelete, IconEdit, IconMeatballsMenu, IconNewChat, IconSpace } from '@unique/icons';
import { ToastVariant, useScreens, useToast } from '@unique/shared-library';
import cn from 'classnames';
import { FC, KeyboardEventHandler, Ref, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useChatRename } from './ChatRenameProvider';

interface ChatHeaderProps {
  assistantTitle: string;
  customTitle?: string;
  buttonText?: string;
  resetHeader?: () => void;
  drawerPosition?: DRAWER_POSITION;
  onClickNewChat?: () => void;
}

const ChatHeader: FC<ChatHeaderProps> = ({
  assistantTitle,
  customTitle,
  buttonText = 'New Chat',
  resetHeader,
  drawerPosition = DRAWER_POSITION.BOTTOM,
  onClickNewChat,
}) => {
  const isTranslation = buttonText === 'New Translation';
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [chatTitleIsFocused, setChatTitleIsFocused] = useState(false);
  const [inputRef, setInputRef] = useState(null);

  const { id } = useParams<{ id: string }>();
  const { deleteChat } = useChatDelete();
  const { data } = useChatQuery({
    chatId: id,
  });
  const currentChat = data?.chat;
  const [chatTitle, setChatTitle] = useState('');
  const { handleClickRename } = useChatRename();
  const { updateChatTitle } = useChatUpdateTitle(id);
  const { showToast } = useToast();
  const { isMobile } = useScreens();

  useEffect(() => {
    if (!currentChat?.title) return;
    setChatTitle(currentChat?.title);
  }, [currentChat?.title]);

  const handleClickNewChat = () => {
    if (customTitle && typeof resetHeader === 'function') {
      resetHeader();
      return;
    }

    // should navigate to `/space/${assistantId}`;
    onClickNewChat?.();
  };

  const handleClickRenameChat = (event) => {
    handleClickRename(event, currentChat);
    setIsDrawerOpen(false);
  };

  const handleRenameChat = () => {
    setChatTitleIsFocused(false);
    if (currentChat?.title === chatTitle) return;
    if (!chatTitle.length) {
      setChatTitle(currentChat?.title);
      showToast({ message: 'Title cannot be blank.', variant: ToastVariant.INFO });
      return;
    }
    updateChatTitle(chatTitle);
  };

  const handleKeyDownChatInput: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      handleRenameChat();
      inputRef.current?.blur();
    }
  };
  const handleClickChatTitle = () => setChatTitleIsFocused(!isMobile);

  const handleChangeChatTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChatTitle(event.target.value);
  };

  return (
    <DrawerProvider position={drawerPosition} className={`${isTranslation ? 'h-[9rem]' : ''}`}>
      <div className="flex w-full min-w-0 flex-wrap items-center justify-start gap-y-3 px-3.5 py-4 sm:px-6">
        <span className="body-2 border-background-variant text-on-background-dimmed flex max-w-[50%] items-center gap-x-2 truncate whitespace-nowrap rounded-[8px] border px-2 xl:max-w-full">
          <span className="shrink-0">
            <IconSpace />
          </span>
          <span className="truncate">{assistantTitle}</span>
        </span>
        <div className="subtitle-2 text-on-background-main min-w-0 flex-1 truncate whitespace-nowrap px-4">
          {customTitle ? (
            customTitle
          ) : (
            <>
              {!chatTitleIsFocused ? (
                <div
                  className={cn({
                    'border-background w-[99%] min-w-0 truncate whitespace-nowrap rounded-[8px] px-[11px] transition':
                      true,
                    'hover:bg-background py-2.5': !isMobile,
                  })}
                  onClick={handleClickChatTitle}
                >
                  {chatTitle}
                </div>
              ) : (
                <InputField
                  name="title"
                  classes="border-transparent hover:!border-primary-cta subtitle-2 pl-2.5"
                  inputWrapperClasses="relative"
                  value={chatTitle}
                  autoFocus
                  handleChange={handleChangeChatTitle}
                  onBlur={handleRenameChat}
                  onKeyDown={handleKeyDownChatInput}
                  setRef={(ref: Ref<HTMLInputElement | null>) => {
                    setInputRef(ref);
                  }}
                  maxLength={CHAT_TITLE_MAX_LENGTH}
                />
              )}
            </>
          )}
        </div>
        <ButtonIcon
          icon={<IconNewChat />}
          onClick={handleClickNewChat}
          className="!hidden sm:!flex"
        >
          {buttonText}
        </ButtonIcon>
        {/* meatballs menu including chat delete action is only visible on mobile */}
        <div className="ml-auto pr-1.5 sm:hidden">
          <ButtonIcon
            icon={<IconMeatballsMenu />}
            variant={ButtonVariant.SECONDARY}
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              setIsDrawerOpen(true);
            }}
          />
        </div>
        <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
          <div className="mt-8">
            <MenuItem
              icon={<IconNewChat />}
              title={buttonText}
              handleClick={() => handleClickNewChat()}
            />
            <MenuItem
              icon={<IconEdit width="18px" />}
              title="Rename Chat"
              handleClick={handleClickRenameChat}
            />
            <MenuItem
              icon={<IconDelete />}
              title={isTranslation ? 'Delete Translation' : 'Delete Chat'}
              handleClick={(event) => deleteChat(event, currentChat?.id)}
            />
          </div>
        </Drawer>
      </div>
    </DrawerProvider>
  );
};

export default ChatHeader;
