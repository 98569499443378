import { ContentById } from '@/lib/swr/types';
import { duration } from '@unique/component-library';
import { isNumber } from 'lodash';

type Props = {
  content: ContentById;
};

export default function FileUploadDetails({ content }: Props) {
  const showExpirationInfo = isNumber(content.expiresInDays);
  return (
    <div className="body-2 text-on-background-dimmed mt-0.5">
      {showExpirationInfo ? (
        <>
          {content.expiresInDays < 0 && (
            <>
              Expired{' '}
              {Math.abs(content.expiresInDays) === 1
                ? 'yesterday'
                : `${Math.abs(content.expiresInDays)} days ago`}{' '}
            </>
          )}
          {content.expiresInDays === 0 && <>Expired today</>}
          {content.expiresInDays > 0 && (
            <>
              Added {duration(content.createdAt)} ago | Will be deleted in {content.expiresInDays}{' '}
              day{content.expiresInDays === 1 ? '' : 's'}
            </>
          )}
        </>
      ) : (
        <>Added {duration(content.createdAt)} ago</>
      )}
    </div>
  );
}