import { ToastVariant } from '@unique/shared-library';

enum DOWNLAOD_RESPONSE_CODES {
  DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILED = 'DOWNLOAD_FAILED',
  ORDER_NOT_DONE = 'ORDER_NOT_DONE',
  ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
}
export const analyticsDownloadResponseParser = (response: string) => {
  switch (response) {
    case DOWNLAOD_RESPONSE_CODES.DOWNLOAD_FAILED:
      return {
        message: 'Download failed',
        variant: ToastVariant.ERROR,
      };
    case DOWNLAOD_RESPONSE_CODES.ORDER_NOT_DONE:
      return {
        message: 'Order is not done yet',
        variant: ToastVariant.ERROR,
      };
    case DOWNLAOD_RESPONSE_CODES.DOWNLOAD_SUCCESS:
      return {
        message: 'Order downloaded successfuly',
        variant: ToastVariant.SUCCESS,
      };
    case DOWNLAOD_RESPONSE_CODES.ORDER_NOT_FOUND:
      return {
        message: 'Order not found',
        variant: ToastVariant.ERROR,
      };
    default:
      return {
        message: 'Unknown response',
        variant: ToastVariant.ERROR,
      };
  }
};
