'use client';
import { updateEditingCell } from '@/store/slices/dueDiligence';
import { CellEditing, CellEditRequest, MagicTable, useMagicTable } from '@unique/component-library';
import DueDiligenceButtons from './DueDiligenceButtons';
import { useAppDispatch } from '@/store';
import { useContext, useEffect } from 'react';
import useUpdateCell from '@/hooks/useUpdateCell';
import { LayoutContext } from '@unique/shared-library';
import { SpaceHeader } from '../Space/SpaceHeader';
import useDueDiligenceQuery from '@/hooks/useDueDiligenceQuery';
import { MenuItemDef } from 'ag-grid-community';

export default function DueDiligenceComponent({
  spaceId,
  spaceSlug: sheetId,
}: {
  spaceId: string;
  spaceSlug: string;
}) {
  const dispatch = useAppDispatch();

  const { setHeaderItems } = useContext(LayoutContext);

  const { dueDiligenceData, loadingTableData, requeryDueDiligence } = useDueDiligenceQuery({
    sheetId,
  });

  const {
    tableRef,
    stopEditingCell,
    updateCellValues,
    exportAsCSV,
    getLastOccupiedColumn,
    getColumnIndex,
    getColumnAtIndex,
  } = useMagicTable({
    tableData: dueDiligenceData?.rows || [],
    isLoading: loadingTableData,
  });

  const { updateCell } = useUpdateCell({
    sheetId,
    updateCellValues,
    getColumnAtIndex,
    requeryDueDiligence,
  });

  const handleCellEditingStart = (event: CellEditing) => {
    dispatch(updateEditingCell(event));
  };

  const handleCellEditingEnd = (event: CellEditing) => {
    dispatch(updateEditingCell(event));
  };

  useEffect(() => {
    return () => {
      dispatch(updateEditingCell(null));
    };
  }, []);

  const handleCellEditRequest = ({ rowIndex, columnId, newValue, event }: CellEditRequest) => {
    // The event from the ag-grid Edit component is called "edit"
    // If the event is coming from a different source that is not known by AG-Grid, it is undefined

    if (!event.source) return;

    updateCell({
      columnOrder: getColumnIndex(columnId),
      rowOrder: rowIndex,
      data: newValue,
      sheetId,
    });
  };

  useEffect(() => {
    if (spaceId) {
      setHeaderItems([
        <SpaceHeader key={`space-header-${spaceId}`} title={dueDiligenceData?.name} />,
      ]);
    }
    return () => {
      setHeaderItems([]);
    };
  }, [spaceId, dueDiligenceData]);

  const getContextMenuItems = (): (string | MenuItemDef)[] => {
    return [
      {
        name: 'View History',
      },
    ];
  };

  return (
    <div className="flex flex-col justify-start">
      <DueDiligenceButtons
        getTableData={exportAsCSV}
        stopEditingCell={stopEditingCell}
        sheetId={sheetId}
        updateCell={updateCell}
        getLastOccupiedColumn={getLastOccupiedColumn}
      />
      <MagicTable
        ref={tableRef}
        cellEditingEnd={(event) => handleCellEditingEnd(event)}
        contextMenuItems={getContextMenuItems}
        cellEditingStart={(event) => handleCellEditingStart(event)}
        handleCellEditRequest={handleCellEditRequest}
      />
    </div>
  );
}
