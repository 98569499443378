import { IconSync, IconDownload } from '@unique/icons';

export const getStatusInfo = (statusResponse: BenchmarkStatusResponse) => {
  switch (true) {
    case statusResponse.status == 'ABORTED':
      return {
        text: 'Aborted',
        classes: '!bg-error-dark !px-2',
        icon: (
          <span className="text-on-background-dimmed cursor-pointer">
            <IconDownload width="17px" height="17px" />
          </span>
        ),
      };
    case statusResponse.filename == 'NOT_AVAILABLE':
      return {
        text: 'Error',
        classes: '!bg-error-dark !px-2',
        icon: null,
      };
    case statusResponse.error + statusResponse.done < statusResponse.total:
      return {
        text: `In Progress... (${statusResponse.done} done ${
          statusResponse.error > 0 ? `/ ${statusResponse.error} error ` : ''
        }/ ${statusResponse.total} total)`,
        classes: '!bg-info !px-2',
        icon: (
          <span className="text-info">
            <IconSync width="17px" height="17px" />
          </span>
        ),
      };
    case statusResponse.done == statusResponse.total:
      return {
        text: `Ready without errors (${statusResponse.total} total)`,
        classes: '!bg-success-dark !px-2',
        icon: (
          <span className="text-on-background-dimmed cursor-pointer">
            <IconDownload width="17px" height="17px" />
          </span>
        ),
      };
    case statusResponse.error + statusResponse.done == statusResponse.total:
      return {
        text: `Ready including failed entries (${statusResponse.error} errors / ${statusResponse.total} total)`,
        classes: '!bg-success-dark !px-2',
        icon: (
          <span className="text-on-background-dimmed cursor-pointer">
            <IconDownload width="17px" height="17px" />
          </span>
        ),
      };
    default:
      return null;
  }
};

export const REFRESH_INTERVAL_BENCHMARKING = 20_000;

export interface BenchmarkStatusNumbers {
  error: number;
  done: number;
  total: number;
}
export interface BenchmarkStatusResponse extends BenchmarkStatusNumbers {
  filename: string;
  status: string;
}
