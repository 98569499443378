import { SUPPORT_EMAIL_PLACEHOLDER } from '@/lib/constants/supportEmailPlaceholder';
import { DEFAULT_SUPPORT_EMAIL } from '@unique/shared-library';

const SUPPORT_EMAIL_CTA_IN_TEXT = `or [contact support](mailto:${SUPPORT_EMAIL_PLACEHOLDER})`;
export const formatSupportEmail = (text: string, supportEmail: string) => {
  if (text?.includes(SUPPORT_EMAIL_PLACEHOLDER)) {
    if (supportEmail) {
      return text.replace(SUPPORT_EMAIL_PLACEHOLDER, supportEmail);
    }
    return text.replace(SUPPORT_EMAIL_CTA_IN_TEXT, '');
  }
  // support old messages before we introduced the support email placeholder
  // back then we used the default support email directly in the message
  if (text?.includes(DEFAULT_SUPPORT_EMAIL) && supportEmail) {
    return text.replace(DEFAULT_SUPPORT_EMAIL, supportEmail);
  }
  return text;
};
