import { AssistantWithUserPrompts } from '@/lib/swr/types';
import { FC } from 'react';
import cn from 'classnames';

type ExploreSpaceListItemProps = {
  assistant: AssistantWithUserPrompts;
  isActive?: boolean;
  handleClickAssistant: () => void;
};

export const ExploreSpaceListItem: FC<ExploreSpaceListItemProps> = ({
  assistant,
  isActive,
  handleClickAssistant,
}) => {
  return (
    <div
      className={cn({
        'body-2 transition-bg transition-background group flex min-h-[40px] w-full cursor-pointer items-center gap-x-3 rounded-[4px]':
          true,
        'text-on-surface hover:bg-background hover:text-primary-cta': !isActive,
        'bg-background-variant text-primary-variant font-bold': isActive,
      })}
      onClick={handleClickAssistant}
    >
      <div
        className={cn({
          'hidden h-[32px] w-[4px] shrink-0 rounded-r-[16px] transition': true,
          '': !isActive,
          'bg-primary-variant !block': isActive,
        })}
      />
      <span
        className={cn({
          'truncate xl:max-w-[90%]': true,
          'transition-[padding] group-hover:px-4 hover:xl:max-w-[98%]': !isActive,
        })}
      >
        {assistant.name}
      </span>
    </div>
  );
};
