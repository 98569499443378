'use client';

import { FC } from 'react';
import { IconAlert, IconCheck } from '@unique/icons';
import { MarkdownPreview } from '@unique/component-library';

type SpaceInfoProps = {
  explanation?: string;
  alert?: string;
  bg?: string;
};

export const SpaceInfo: FC<SpaceInfoProps> = ({ explanation, alert, bg = 'bg-background' }) => {
  return (
    <div className="mx-auto w-full">
      {explanation && (
        <div className={`${bg} my-4 flex w-full rounded-[8px] p-4 text-left`}>
          <IconCheck className="mr-4 shrink-0 grow-0 basis-[24px]" />
          <div className="body-2 text-on-surface -mb-4">
            <MarkdownPreview text={explanation} />
          </div>
        </div>
      )}
      {alert && (
        <div className={`${bg} my-4 flex w-full rounded-[8px] p-4 text-left`}>
          <IconAlert className="mr-4 shrink-0 grow-0 basis-[24px]" />
          <div className="body-2 text-on-surface -mb-4">
            <MarkdownPreview text={alert} />
          </div>
        </div>
      )}
    </div>
  );
};
