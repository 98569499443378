import { FC, useCallback } from 'react';
import cn from 'classnames';
import { Reference } from '@/lib/swr/types';
import { Tooltip, Spinner } from '@unique/component-library';
import { IconReportType, IconWorld, IconCopy } from '@unique/icons';
import { getTruncatedString } from '@unique/shared-library';

interface ChatReferenceContentProps {
  reference: Reference;
  isLoading?: boolean;
  isInternalPdfReference?: boolean;
  showPdfHighlighting?: boolean;
}

const MAX_THRESHOLD = 20;

const ChatReferenceContent: FC<ChatReferenceContentProps> = ({
  reference,
  isLoading = false,
  isInternalPdfReference = false,
  showPdfHighlighting = false,
}) => {
  const getReferenceIcon = useCallback(() => {
    if (!reference.url) {
      return <IconReportType />;
    }
    return isInternalPdfReference && showPdfHighlighting ? <IconCopy /> : <IconWorld />;
  }, [reference.url, isInternalPdfReference, showPdfHighlighting]);
  return (
    <>
      <span className="mr-2 min-w-[18px]">
        {isLoading ? <Spinner size={15} /> : getReferenceIcon()}
      </span>
      {reference.name.length > MAX_THRESHOLD ? (
        <Tooltip
          wrapper={
            <span className="subtitle-2 text-on-background-dimmed group-hover:text-on-surface">
              {getTruncatedString(reference.name, MAX_THRESHOLD)}
            </span>
          }
          className="subtitle-2 !left-0 !max-w-[250px] break-words"
          wrapperClasses={cn({
            '!cursor-default': !reference.url,
          })}
          arrowPosition="none"
        >
          {reference.name}
        </Tooltip>
      ) : (
        <span className="subtitle-2 text-on-background-dimmed group-hover:text-on-surface">
          {reference.name}
        </span>
      )}
      <span className="subtitle-2 bg-background-variant text-on-background-main group-hover:bg-attention-variant group-hover:text-on-attention-variant absolute -left-1 top-0 flex h-4 w-4 items-center justify-center rounded-md transition">
        {reference.sequenceNumber}
      </span>
    </>
  );
};

export default ChatReferenceContent;
