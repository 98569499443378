import { logger } from '@unique/next-commons/logger';
import _ from 'lodash';
import removeMd from 'remove-markdown';

const MIN_CHARS_FOR_HIGHLIGHTING = 10;

const pdfPreviewLogger = logger.child({ package: 'pdf-preview' });

const removeTag = (chunkText: string, tagName: string) => {
  const startTag = `<|${tagName}|>`;
  const endTag = `<|/${tagName}|>`;
  const startIndex = chunkText.indexOf(startTag);
  const endIndex = chunkText.indexOf(endTag, startIndex + startTag.length);
  if (startIndex !== -1 && endIndex !== -1) {
    return chunkText.substring(0, startIndex) + chunkText.substring(endIndex + endTag.length);
  }
  return chunkText;
};

export const createHighlightsFromChunkText = (chunkText: string): (string | RegExp)[] => {
  // remove tags including text inside specific tags, e.g. <|info|> and <|document|>
  chunkText = removeTag(chunkText, 'info');
  chunkText = removeTag(chunkText, 'document');
  // remove markdown syntax + split sentences, new line, pipe, opening and closing bracket and underscore
  let lines: string[] = removeMd(chunkText).split(/[.\n|()_]+/);

  // Filter out empty lines and duplicated text
  lines = _.uniq(lines).filter(
    (line: string) => line.length > MIN_CHARS_FOR_HIGHLIGHTING && line.trim() !== '',
  );
  // create regexp for each line that makes all spaces for a line optional
  // this is needed because the search function of the pdf-react-viewer library
  // does not work with spaces in case of a line break
  const regExpLines = lines.map((line) => {
    line = line.replaceAll('**', '').replaceAll('\\', '');
    line = line.replaceAll('+', '\\+');
    try {
      return new RegExp(line.replaceAll(' ', '\\s*'));
    } catch (e) {
      pdfPreviewLogger.error(e);
      return line;
    }
  });
  return regExpLines;
};

export const extractPagesFromName = (name: string) => {
  // Format of name: fileName.pdf : 1,2,3
  const lastColonIndex = name.lastIndexOf(':');
  if (lastColonIndex === -1) return [];

  const fileNameAndPages = [name.slice(0, lastColonIndex), name.slice(lastColonIndex + 1)];
  if (fileNameAndPages.length < 2) return [];

  const pages = fileNameAndPages[1].split(',');
  if (pages.length < 1) return [];

  return pages.map((page) => parseInt(page));
};

export const hidePagesFromName = (name: string) => {
  // Find the last colon in the string
  const lastColonIndex = name.lastIndexOf(':');
  if (lastColonIndex === -1) return name;

  // Extract the file name part before the last colon
  const fileName = name.slice(0, lastColonIndex).trim();

  return fileName;
};
