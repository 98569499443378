import { MessageSubscriptionSubscription, MessagesQuery } from '@/@generated/graphql';
import { logger } from '@unique/next-commons/logger';

const log = logger.child({
  package: 'chat',
  namespace: 'cache:messages-update-resolver',
});

export function messagesUpdateResolver(
  result: MessageSubscriptionSubscription,
  currentData?: MessagesQuery,
): MessagesQuery {
  // Warning: This can lead to a situation if called directly with mutate,
  // where the cache is being wiped, just because the `currentData` is undefined due to a race condition.
  //
  // Current solution is to use a state and mutate with this resolver within a distinct effect.
  if (!currentData || !currentData.messages) {
    log.warn({ result }, 'No current data found, returning empty messages array.');
    return { messages: result ? [result.messageUpdate] : [] };
  }

  const index = currentData.messages.findIndex((message) => message.id === result.messageUpdate.id);
  return index >= 0
    ? {
        ...currentData,
        messages: currentData.messages.map((message) => {
          if (message.id !== result.messageUpdate.id) return message;
          return {
            ...result.messageUpdate,
            // preserve feedback that was updated optimistically
            feedback: message.feedback,
          };
        }),
      }
    : { ...currentData, messages: [...currentData.messages, result.messageUpdate] };
}
