import { IconSpace, IconArrowDown } from '@unique/icons';
import { LayoutContext, useScreens } from '@unique/shared-library';
import { FC, useContext } from 'react';
import cn from 'classnames';

export enum ExploreSpaceButtonVariant {
  LIGHT = 'light',
  DARK = 'dark',
}

type ExploreSpaceButtonProps = {
  variant?: ExploreSpaceButtonVariant;
  onClick?: () => void;
};
export const ExploreSpaceButton: FC<ExploreSpaceButtonProps> = ({
  variant = ExploreSpaceButtonVariant.LIGHT,
  onClick,
}) => {
  const { setIsMenuExpanded } = useContext(LayoutContext);
  const { isMobile } = useScreens();
  const handleClickExploreSpaces = () => {
    
    if (isMobile) {
      setIsMenuExpanded(false);
    }
    // Should navigate to navigate('/space/explore');
    onClick?.();
  };

  return (
    <button
      onClick={handleClickExploreSpaces}
      className={cn({
        'subtitle-2 flex w-full items-center gap-x-2 rounded-[8px] border px-4 py-2 transition':
          true,
        'text-on-secondary border-[#fff] border-opacity-30 opacity-80 hover:border-opacity-100 hover:opacity-100':
          variant === ExploreSpaceButtonVariant.LIGHT,
        'border-control text-on-surface hover:border-on-surface':
          variant === ExploreSpaceButtonVariant.DARK,
      })}
    >
      <IconSpace />
      Explore Spaces
      <IconArrowDown className="ml-auto origin-center -rotate-90" />
    </button>
  );
};
