import { Dropzone } from '@unique/component-library';
import { IconUpload } from '@unique/icons';
import { MAX_FILE_SIZE, SUPPORTED_MIME_TYPES_EXCEL } from '@unique/shared-library';
import { FC } from 'react';
import { FileRejection, FileWithPath } from 'react-dropzone';

type BenchmarkingDropzoneProps = {
  isUploading: boolean;
  handleUpload: (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => void;
};

const BenchmarkingDropzone: FC<BenchmarkingDropzoneProps> = ({ isUploading, handleUpload }) => {
  return (
    <div className={`flex flex-col gap-4 ${isUploading ? 'cursor-not-allowed opacity-50' : ''}`}>
      <Dropzone
        onDrop={(acceptedFiles, fileRejections) => handleUpload(acceptedFiles, fileRejections)}
        onError={(e) => console.log(e)}
        accept={SUPPORTED_MIME_TYPES_EXCEL}
        maxFiles={1}
        maxSize={MAX_FILE_SIZE}
        additonalClassname="transition-all text-center rounded-2xl cursor-pointer border-[1px] border-dashed border-on-background-dimmed flex justify-center items-center p-12 dropzone-basic !text-on-background-dimmed hover:border-primary-cta hover:bg-surface"
        wrapperClassname={isUploading ? 'pointer-events-none' : ''}
      >
        <div className="text-on-background-main flex flex-col items-center">
          <span className="mb-3">
            <IconUpload width="40px" height="33px" />
          </span>
          <div className="mb-1.5">
            <span className="subtitle-2 hidden sm:block">Drag & Drop or Click to Browse</span>
            <span className="sm:hidden">Click to Upload</span>
          </div>
          <code className="body-2 text-on-background-dimmed">Excel File</code>
        </div>
      </Dropzone>
    </div>
  );
};

export default BenchmarkingDropzone;
