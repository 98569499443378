import cn from 'classnames';

type Props = {
  children: React.ReactNode;
  fullDetails: boolean;
  initials: string;
  className: string;
};

export default function ContentWrapper({ children, fullDetails, initials, className }: Props) {
  return (
    <div className={cn({ 'flex w-full gap-2': true, className })}>
      {fullDetails && (
        <div className="subtitle-2 label bg-background-variant text-on-background-main flex h-10 w-10 items-center justify-center rounded-full">
          {initials}
        </div>
      )}
      <div className="w-[calc(100%-48px)] flex-1 rounded-lg">
        <div className="bg-surface mx-auto flex w-full flex-1 flex-col flex-wrap items-start">
          {children}
        </div>
      </div>
    </div>
  );
}