export const escapeHtmlOutsideCodeBlocks = (text: string) => {
  // Split the text into lines to handle block code and inline code separately
  if (!text) return '';
  const lines = text.split('\n');
  let inCodeBlock = false;
  const result = [];

  lines.forEach((line) => {
    // Check for the start or end of a fenced code block
    if (line?.trim().startsWith('```')) {
      inCodeBlock = !inCodeBlock;
      result.push(line);
    } else if (inCodeBlock) {
      // If we're inside a code block, just add the line
      result.push(line);
    } else {
      // We're outside a code block, escape < and >
      // First, handle inline code by splitting and escaping parts outside backticks
      const parts = line?.split(/(`[^`]*`)/); // Split line while keeping inline code blocks
      for (let i = 0; i < parts.length; i++) {
        if (i % 2 === 0) {
          // Outside of backticks
          parts[i] = parts[i].replace(/</g, '&lt;').replace(/>/g, '&gt;');
        }
      }
      result.push(parts.join(''));
    }
  });
  return result.join('\n');
};
