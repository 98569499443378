import { DueDiligenceQuery } from '@/@generated/graphql';
import Skeleton from '@unique/component-library/src/Skeleton';
import DueDiligenceHistoryTable from './DueDiligenceHistoryTable';
import { useMemo } from 'react';
import { categorizeDate } from '@/helpers/timeCalculation';
import { format } from 'date-fns';

type Props = {
  isLoading: boolean;
  dueDiligence: DueDiligenceQuery['dueDiligence'];
};

export type RowData = {
  id: string;
  name: string;
  lastEdited: string;
};

export default function DueDiligenceList({ dueDiligence, isLoading }: Props) {
  const dueDiligenceList = useMemo(() => {
    if (!dueDiligence?.length) return;
    const categorizedData = {
      today: [],
      yesterday: [],
      sevenDays: [],
      thirtyDays: [],
      older: [],
    };

    // TODO Move this sorting logic to the server
    const sortedDueDiligence = dueDiligence.sort((a, b) => {
      return (
        new Date(b.magicTableSheet.createdAt).getTime() -
        new Date(a.magicTableSheet.createdAt).getTime()
      );
    });

    sortedDueDiligence.forEach((data) => {
      const category = categorizeDate(data.magicTableSheet.createdAt);
      categorizedData[category].push({
        name: data.magicTableSheet.name,
        lastEdited: format(new Date(data.magicTableSheet.createdAt), "MMM d, yyyy 'at' HH:mm"),
        id: data.magicTableSheet.id,
      });
    });

    return categorizedData;
  }, [dueDiligence]);

  return (
    <div>
      {isLoading && (
        <div className="flex flex-col gap-5">
          {Array.from({ length: 20 }).map((_, index) => (
            <Skeleton key={index} />
          ))}
        </div>
      )}
      {dueDiligenceList?.today?.length ? (
        <DueDiligenceHistoryTable headerTitle="Today" rowData={dueDiligenceList.today} />
      ) : null}
      {dueDiligenceList?.yesterday?.length ? (
        <DueDiligenceHistoryTable headerTitle="Yesterday" rowData={dueDiligenceList.yesterday} />
      ) : null}
      {dueDiligenceList?.sevenDays?.length ? (
        <DueDiligenceHistoryTable
          headerTitle="Previous 7 days"
          rowData={dueDiligenceList.sevenDays}
        />
      ) : null}
      {dueDiligenceList?.thirtyDays?.length ? (
        <DueDiligenceHistoryTable
          headerTitle="Previous 30 days"
          rowData={dueDiligenceList.thirtyDays}
        />
      ) : null}
      {dueDiligenceList?.older?.length ? (
        <DueDiligenceHistoryTable headerTitle="Earlier" rowData={dueDiligenceList.older} />
      ) : null}
    </div>
  );
}
