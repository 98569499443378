'use client';

import { Role } from '@/@generated/graphql';
import { escapeHtmlOutsideCodeBlocks } from '@/helpers/escapeHtmlOutsideCodeBlocks';
import { extractMessageCodeLanguage, messageHasCode } from '@/helpers/messages';
import { INTERNAL_URL_PREFIX, isInternalUniqueUrl } from '@/helpers/references';
import { Message } from '@/lib/swr/types';
import { useAppSelector } from '@/store';
import {
  CopyToClipboard,
  DefaultMarkdownLink,
  MarkdownPreview,
  duration,
  getInitials,
} from '@unique/component-library';
import { IconAssistant } from '@unique/icons';
import { ClientThemeContext, useCopyToClipboard } from '@unique/shared-library';
import cn from 'classnames';
import { FC, memo, useContext } from 'react';
import { NormalComponent } from 'react-markdown/src/ast-to-react';
import { useAuth } from 'react-oidc-context';
import FeedbackButtons from '../FeedbackButtons';
import { ChatMessageDebugFooter } from './ChatMessageDebugFooter';
import {
  ChatReferenceButton,
  PROMPT_URL_PREFIX_HTTP,
  PROMPT_URL_PREFIX_HTTPS,
} from './ChatReferenceButton';
import { ChatReferences } from './ChatReferences';
import { SavePromptControl } from './SavePromptControl';
import { formatSupportEmail } from '@/helpers/formatSupportEmail';
import { isEqual } from 'lodash';

interface MessageItemProps {
  message: Message;
  showChunks: boolean;
  showPdfHighlighting: boolean;
  redirectInternalStorageOnly: boolean;
  onThumbsClick: (messageId: string, isPositive?: boolean) => void;
  onSavePromptClick: (prompt: string) => void;
  handleSelectPrompt: (prompt: string) => void;
}

const linkContainsPrompt = (href: unknown) => {
  return href && typeof href === 'string' && href.startsWith(PROMPT_URL_PREFIX_HTTPS);
};

// if message has the same props, we don't need to re-render it
// if message is streaming, it will change all the time while streaming so we need to re-render
const messagePropsDidNotChange = (prevProps: MessageItemProps, nextProps: MessageItemProps) => {
  return (
    isEqual(prevProps.message, nextProps.message) &&
    prevProps.showChunks === nextProps.showChunks &&
    prevProps.showPdfHighlighting === nextProps.showPdfHighlighting &&
    prevProps.redirectInternalStorageOnly === nextProps.redirectInternalStorageOnly
  );
};

export const MessageItem: FC<MessageItemProps> = ({
  message,
  showChunks,
  showPdfHighlighting,
  redirectInternalStorageOnly,
  onThumbsClick,
  onSavePromptClick,
  handleSelectPrompt,
}) => {
  const {
    user: { profile },
  } = useAuth();
  const initials = getInitials(profile.name, profile.email);
  const { copiedText, copy } = useCopyToClipboard();
  const { copiedText: copiedCode, copy: copyCode } = useCopyToClipboard();
  const isAssistantMessage = message.role === Role.Assistant;
  const isUserMessage = message.role === Role.User;
  const chatMessageWithoutReferences = message?.text?.replace(/<sup>.*?<\/sup>/g, '');
  const hasCode = messageHasCode(message?.text);
  const hasPrompt =
    message?.text?.includes(PROMPT_URL_PREFIX_HTTPS) ||
    message?.text?.includes(PROMPT_URL_PREFIX_HTTP);
  const codeLang = hasCode ? extractMessageCodeLanguage(message?.text) : '';
  const shouldHaveCopyButton = isAssistantMessage && !hasCode && !hasPrompt;
  const shouldHaveAddPromptButton = isUserMessage;
  const chatImageUrls = useAppSelector((state) => state.chat.chatImageUrls) ?? {};
  const { supportEmail } = useContext(ClientThemeContext);

  const ChatMessageMarkdownImage: NormalComponent = ({ src }) => {
    if (isInternalUniqueUrl(src?.toString())) {
      // extract the content id from src
      const contentId = src?.toString().replace(INTERNAL_URL_PREFIX, '');
      if (chatImageUrls[contentId]) {
        // eslint-disable-next-line @next/next/no-img-element
        return <img src={chatImageUrls[contentId]} className="my-8 max-h-[400px] max-w-[850px]" />;
      } else {
        return null;
      }
    }
    // Ignore external images in the chat
    return null;
  };

  const ChatMessageMarkdownLink: NormalComponent = ({ children, href, target }) => {
    // Check if link contains a prompt and if it does, render a button instead of a link
    // This is to allow the user to select the prompt
    // Also, we show the file name including file type as the button label (e.g. "file.pdf")
    // based on this, we can extract the file type icon (e.g. PDF icon)
    if (
      linkContainsPrompt(href) &&
      handleSelectPrompt &&
      typeof handleSelectPrompt === 'function'
    ) {
      return (
        <ChatReferenceButton handleSelectPrompt={handleSelectPrompt} href={href}>
          {children}
        </ChatReferenceButton>
      );
    }

    if (href === 'https://expired') {
      return <ChatReferenceButton isExpired>{children}</ChatReferenceButton>;
    }
    return (
      <DefaultMarkdownLink href={href} target={target}>
        {children}
      </DefaultMarkdownLink>
    );
  };

  const formatMessage = () => {
    if (isUserMessage) {
      // Escape HTML outside code blocks for user message
      return escapeHtmlOutsideCodeBlocks(message.text);
    }
    return formatSupportEmail(message.text, supportEmail);
  };

  return (
    <div className="mx-auto flex w-full max-w-[928px] items-start">
      {isUserMessage ? (
        <div className="subtitle-2 label bg-background-variant text-on-background-main flex h-10 w-10 items-center justify-center rounded-full">
          {initials}
        </div>
      ) : (
        <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full">
          <IconAssistant />
        </div>
      )}
      <div className="w-[calc(100%-48px)] flex-1 rounded-lg pl-2">
        <div
          className={cn({
            'body-1 group relative rounded-[8px] pb-6 pt-2.5 transition': true,
            'hover:bg-background': shouldHaveCopyButton || shouldHaveAddPromptButton,
          })}
        >
          <MarkdownPreview
            text={formatMessage()}
            handleSelectPrompt={handleSelectPrompt}
            copyCode={copyCode}
            copiedCode={copiedCode}
            codeLang={codeLang}
            customComponents={{
              a: ChatMessageMarkdownLink,
              img: ChatMessageMarkdownImage,
            }}
            wrapCode
          />
          {shouldHaveCopyButton && (
            <CopyToClipboard
              text={chatMessageWithoutReferences || ''}
              copiedText={copiedText}
              copy={copy}
            />
          )}
          {shouldHaveAddPromptButton && (
            <SavePromptControl
              prompt={chatMessageWithoutReferences || ''}
              onSavePromptClick={onSavePromptClick}
            />
          )}
        </div>

        {!!message.references?.length && (
          <div className="text-2xs -mx-3 mt-3 flex flex-col flex-wrap items-start px-3 pt-2">
            <div className="text-2xs -mx-3 flex flex-wrap items-start px-3">
              <ChatReferences
                references={message.references}
                showPdfHighlighting={showPdfHighlighting}
                redirectInternalStorageOnly={redirectInternalStorageOnly}
              />
            </div>
          </div>
        )}

        <div className="text-2xs -mx-3 mt-3 flex flex-wrap justify-end px-3 pt-2">
          {isUserMessage && (
            <div className="body-2 text-on-background-dimmed mt-2 tracking-widest">
              {duration(message.createdAt)} ago
            </div>
          )}
          {isAssistantMessage && (
            <FeedbackButtons
              id={message.id}
              feedback={message.feedback}
              onThumbsClick={onThumbsClick}
            />
          )}
        </div>
        {showChunks && <ChatMessageDebugFooter message={message} />}
      </div>
    </div>
  );
};
export default memo(MessageItem, messagePropsDidNotChange);
