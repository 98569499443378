import { FC } from 'react';
import cn from 'classnames';
import { IconWarning } from '@unique/icons';

interface AssistantCharacterLimitProps {
  inputLimit: number;
  inputSize: number;
}

const AssistantCharacterLimit: FC<AssistantCharacterLimitProps> = ({ inputSize, inputLimit }) => {
  const hasError = inputSize > inputLimit;

  return (
    <div
      className={cn({
        'body-1 flex': true,
        'text-on-background-dimmed': !hasError,
        'text-error-dark': hasError,
      })}
    >
      {hasError && <IconWarning className="mr-2 items-center" />}
      <span>
        {inputSize}/{inputLimit}
      </span>
      {hasError && <span className="ml-2">Shorten by {inputSize - inputLimit} characters</span>}
    </div>
  );
};

export default AssistantCharacterLimit;
