// These are temporary questions that can be asked the model pending when we get the real questions from the agentic
export const magicTableColumns = [
  {
    id: '1',
    title: 'Audited Financial Statements for the Past Three Years',
    question:
      'Can you provide audited financial statements for the past three years, including income statements, balance sheets, and cash flow statements?',
  },
  {
    id: '2',
    title: 'Pending Legal Actions and Regulatory Investigations',
    question:
      'Are there any pending legal actions, lawsuits, or regulatory investigations involving your company?',
  },
  {
    id: '3',
    title: 'Detailed Ownership Structure and Affiliates',
    question:
      "Can you detail the company's ownership structure, including subsidiaries and affiliate relationships?",
  },
  {
    id: '4',
    title: 'Intellectual Property and Potential Disputes',
    question:
      'What patents, trademarks, or copyrights does the company hold, and are there any IP disputes?',
  },
  {
    id: '5',
    title: 'Copies of Major Customer and Supplier Contracts',
    question: 'Can you provide copies of major contracts with customers, suppliers, and partners?',
  },
  {
    id: '6',
    title: 'Compliance with Industry Regulations and Standards',
    question: 'How does the company ensure compliance with industry regulations and standards?',
  },
  {
    id: '7',
    title: 'Backgrounds of Key Management and Board Members',
    question: 'Can you provide backgrounds and qualifications of key management and board members?',
  },
  {
    id: '8',
    title: 'List of Major Clients and Retention Rates',
    question: 'Who are your major clients, and what is the customer retention rate?',
  },
  {
    id: '9',
    title: 'Primary Operational Risks and Mitigation Strategies',
    question:
      'What are the primary risks facing the business, and what mitigation strategies are in place?',
  },
  {
    id: '10',
    title: "Company's Strategic Goals and Five-Year Financial Projections",
    question:
      "What are the company's strategic goals and financial projections for the next five years?",
  },
];
