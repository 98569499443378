'use client';

import cn from 'classnames';
import {
  FC,
  KeyboardEventHandler,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';

import { ChatUpload, MessageCreateInput, Role, SortOrder } from '@/@generated/graphql';
import { getLastMessageId } from '@/helpers/messages';
import {
  getUseMessagesQueryKey,
  useMessageCreateMutation,
  useMessagesQuery,
} from '@/lib/swr/hooks';
import { Assistant, ContentById } from '@/lib/swr/types';
import {
  ButtonIcon,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  LoadingText,
  Textarea,
  useOutsideClick,
} from '@unique/component-library';
import { IconPaperPlane, IconStop, IconUploadArrow } from '@unique/icons';
import { useRoles } from '@unique/next-commons/authorization';
import {
  FeatureFlagContext,
  LayoutContext,
  ScrollWrapperContext,
  isIngestionDone,
} from '@unique/shared-library';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { PoweredByDisclaimer } from '../PoweredByDisclaimer';
import { PromptSuggestions } from '../Space/PromptSuggestions';
import AssistantCharacterLimit from './AssistantCharacterLimit';
import ChatHeader from './ChatHeader';
import { ContentItemUploading } from './ContentItemUploading';
import { useAppDispatch, useAppSelector, stopStream } from '@/store';
import { ClientContext, Service } from '@unique/next-commons/swr';

interface ChatInputProps {
  handlePromptSent?: (prompt: string) => void;
  selectedPrompt?: { prompt: string } | null;
  currentChatAssistant?: Assistant;
  content?: ContentById[] | null;
  isIngesting?: boolean;
  chatUploadEnabled?: boolean;
  handleChatUploadClick: MouseEventHandler<HTMLButtonElement>;
}

interface ChatFormData {
  prompt: string;
}

export const ChatInput: FC<ChatInputProps> = ({
  handlePromptSent,
  selectedPrompt,
  currentChatAssistant,
  content,
  isIngesting,
  chatUploadEnabled,
  handleChatUploadClick,
}) => {
  const {
    reset,
    handleSubmit,
    register,
    setValue,
    formState: { isValid },
  } = useForm<ChatFormData>({ reValidateMode: 'onChange', mode: 'onChange' });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const [isOnFocus, setIsOnFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasInput, setHasInput] = useState(false);
  const { clients } = useContext(ClientContext);

  const [text, setText] = useState('');
  const { allowUnlimitedChatInput } = useRoles();
  const { setHeaderItems } = useContext(LayoutContext);
  const { flags } = useContext(FeatureFlagContext);

  const inputDivRef = useRef<HTMLDivElement>();

  useOutsideClick(inputDivRef, () => {
    if (!isOnFocus) return;
    setIsOnFocus(true);
  });

  const chatInputWrapperRef = useRef<HTMLDivElement>();
  const { scrollToBottom } = useContext(ScrollWrapperContext);

  const { trigger: createMessage } = useMessageCreateMutation(getUseMessagesQueryKey());

  const chatId = typeof id === 'string' ? id : null;

  const streams = useAppSelector((state) => state.chat.streams);
  const isStreaming = useMemo(() => {
    return streams.some((stream) => stream.chatId === id);
  }, [streams]);

  useEffect(() => {
    if (!selectedPrompt || !selectedPrompt.prompt) return;
    setHasInput(true);
    setValue('prompt', selectedPrompt.prompt);
    setText(selectedPrompt.prompt);
  }, [selectedPrompt, setValue]);

  const { data: messages } = useMessagesQuery(
    chatId
      ? {
          chatId,
          orderBy: [{ createdAt: SortOrder.Asc }],
        }
      : null,
  ); // If it's not on Chat Page, pause request as messages are not necessary.

  // useOutsideClick instead of onBlur as the latest block direct submission of the form
  useOutsideClick(chatInputWrapperRef, () => {
    if (!text) {
      setHasInput(false);
    }
    setIsOnFocus(false);
  });

  const sendPrompt = useCallback(
    (prompt: string) => {
      setIsLoading(true);
      handlePromptSent && handlePromptSent(prompt);

      const lastMessageId = getLastMessageId(messages);
      const payload: {
        chatId?: string | null;
        input: MessageCreateInput;
        assistantId?: string | null;
      } = {
        chatId,
        input: {
          text: prompt,
          role: Role.User,
          ...(lastMessageId && { previousMessage: { connect: { id: lastMessageId } } }),
        },
        assistantId: currentChatAssistant?.id,
      };

      createMessage(payload, {
        revalidate: false,
        throwOnError: false,
        onSuccess: ({ messageCreate }) => {
          setIsLoading(false);
          reset();
          if (!chatId) navigate(`/${messageCreate.chatId}`);
        },
        onError: (err) => {
          console.log('onError', err);
        },
      });
    },
    [chatId, createMessage, handlePromptSent, messages, reset, navigate, currentChatAssistant?.id],
  );

  const handleFormSubmit = async () => {
    sendPrompt(text);
  };

  const onDragEnter = (event: React.DragEvent) => {
    if (currentChatAssistant?.chatUpload !== ChatUpload.Enabled) return;

    let isFile = false;
    if (event.dataTransfer.types) {
      event.dataTransfer.types.forEach((type) => {
        if (type === 'Files') isFile = true;
      });
    }
    if (!isFile) return;
  };

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (hasInputLimitError) return;
    if (text.trim().length < 1) return;

    const target = event.target as HTMLInputElement;
    if (event.key === 'Enter' && !event.shiftKey && target.value && !isLoading) {
      event.preventDefault();
      sendPrompt(target.value);
      scrollToBottom();
      target.focus();
    }
  };

  const onStopStream = () => {
    dispatch(stopStream(chatId, getLastMessageId(messages), clients[Service.NODE_CHAT]));
  };

  useEffect(() => {
    // Set focus on the input box when the user is on the Chat Space
    // or when the user switches between spaces
    if (!text) {
      setHasInput(false);
      // This is a hacky way to show the cursor in the contenteditable div
      setTimeout(() => {
        showCursorForContentEditableElement(inputDivRef.current);
      }, 0);
    }
  }, [text]);

  useEffect(() => {
    // Set focus on contenteditable div when Component is loaded
    setIsOnFocus(true);
    // Show cursor in contenteditable div
    showCursorForContentEditableElement(inputDivRef.current);
  }, []);

  useEffect(() => {
    if (!chatId || !currentChatAssistant) return;

    setHeaderItems([
      <ChatHeader
        key={chatId}
        assistantTitle={currentChatAssistant.name}
        onClickNewChat={() => {
          navigate(`/space/${currentChatAssistant?.id}`);
        }}
      />,
    ]);

    return () => {
      setHeaderItems([]);
    };
  }, [chatId, currentChatAssistant, setHeaderItems]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const prompt = urlParams.get('prompt');
    if (prompt) {
      sendPrompt(prompt);
    }
  }, []);

  const currentAssistantInputLimit = currentChatAssistant?.inputLimit;
  const currentAssistantInputPlaceholder = useMemo(() => {
    if (isIngesting) return '';
    return currentChatAssistant ? `Enter a prompt in ${currentChatAssistant.name}` : 'Ask Unique';
  }, [currentChatAssistant, isIngesting]);

  const hasText = !!text?.length;
  const isInputActive = (hasText || isOnFocus) && !isIngesting;
  const hasInputLimitError =
    currentAssistantInputLimit &&
    text?.length > currentAssistantInputLimit &&
    !allowUnlimitedChatInput;

  const handleSelectPrompt = (prompt: string) => {
    setHasInput(true);
    setValue('prompt', prompt);
    setText(prompt);
  };

  const contentUploading = content?.filter((content) => !isIngestionDone(content.ingestionState));

  const showCursorForContentEditableElement = (target: HTMLElement) => {
    if (!target) return;
    const range = document.createRange();
    const sel = window.getSelection();
    range?.setStart(target?.childNodes?.[0], 0);
    range?.collapse(true);

    sel?.removeAllRanges();
    sel?.addRange(range);
    target.focus();
  };

  return (
    <div className="bg-surface w-full pb-2 pt-1 transition-all">
      <div className="mx-auto" ref={chatInputWrapperRef}>
        <PromptSuggestions
          assistant={currentChatAssistant}
          handleSelectPrompt={handleSelectPrompt}
          isChatInputEmpty={!text?.length}
          isSending={isLoading}
        />
        <div className="flex items-end gap-3">
          <form className="relative flex w-full" onSubmit={handleSubmit(handleFormSubmit)}>
            <div
              className={cn({
                'border-control bg-surface flex-1 rounded-md border-[1px] transition-all': true,
                'border-primary-cta': isInputActive && id,
                'border-primary-cta pb-8': isInputActive && !id,
                'hover:border-primary-cta': !isIngesting,
              })}
            >
              {hasInput || isIngesting ? (
                <Textarea
                  name="prompt"
                  placeholder={currentAssistantInputPlaceholder}
                  className="w-full !flex-1 rounded-md border-0 !py-3 pr-10 focus:pr-4"
                  initialHeight={isIngesting ? 100 : 46}
                  style={{ boxShadow: 'none' }}
                  labelClassname={cn({
                    'block relative': true,
                    '!h-[46px]': !isInputActive && !isIngesting,
                    '!h-[100px]': isIngesting,
                  })}
                  onDragEnter={onDragEnter}
                  onFocus={() => setIsOnFocus(true)}
                  register={register}
                  handleTextAreaChange={setText}
                  onKeyDown={handleKeyDown}
                  autoFocus={!isIngesting}
                  disabled={isIngesting}
                />
              ) : (
                <div
                  role="textbox"
                  onDragEnter={onDragEnter}
                  ref={inputDivRef}
                  className="w-full !flex-1 rounded-md border-0 !py-3 pr-10 outline-none focus:pr-4"
                  autoFocus
                  onClick={(e: MouseEvent) => {
                    setIsOnFocus(true);
                    showCursorForContentEditableElement(e.target as HTMLElement);
                  }}
                  suppressContentEditableWarning
                  onKeyDown={(event) => {
                    if (!text.length && (event.key === 'Backspace' || event.key === 'Delete')) {
                      event.preventDefault();
                      return;
                    }
                    setHasInput(true);
                    setValue('prompt', (event.target as HTMLInputElement).value);
                  }}
                  contentEditable={isOnFocus}
                  tabIndex={0}
                >
                  <div className="body-1 text-on-control-dimmed flex gap-1 px-4">
                    {chatUploadEnabled ? (
                      <span className="w-40 sm:w-full">
                        <p className="truncate sm:hidden">{currentAssistantInputPlaceholder}</p>
                        <span className="hidden sm:inline">
                          {currentAssistantInputPlaceholder}, or{' '}
                          <button
                            role="button"
                            className="text-primary-cta cursor-pointer"
                            title="Upload"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleChatUploadClick(e);
                            }}
                          >
                            Upload in Chat
                          </button>
                        </span>
                      </span>
                    ) : (
                      currentAssistantInputPlaceholder
                    )}
                  </div>
                </div>
              )}
            </div>
            {isIngesting && (
              <div className="body-1 text-on-control-dimmed absolute left-4 top-3">
                <LoadingText>Ingesting knowledge</LoadingText>
              </div>
            )}
            {contentUploading?.length > 0 && (
              <div className="absolute left-4 top-12 flex max-w-[calc(100%-60px)] gap-x-3 overflow-x-auto">
                {content
                  ?.filter((content) => !isIngestionDone(content.ingestionState))
                  .map((content) => <ContentItemUploading content={content} key={content.id} />)}
              </div>
            )}
            {currentAssistantInputLimit && !allowUnlimitedChatInput && (
              <div
                className={cn({
                  'absolute bottom-2.5 left-4': true,
                  'pointer-events-none opacity-0': !isInputActive,
                })}
              >
                <AssistantCharacterLimit
                  inputSize={text.length}
                  inputLimit={currentAssistantInputLimit}
                />
              </div>
            )}
            {flags.FEATURE_FLAG_STOP_STREAMING_UN_4824 && isStreaming ? (
              <ButtonIcon
                variant={ButtonVariant.PRIMARY}
                type={ButtonType.BUTTON}
                icon={!isLoading ? <IconStop height="16" width="16" /> : <></>}
                className={cn({
                  '!absolute bottom-2 right-2': true,
                })}
                onClick={onStopStream}
                buttonSize={ButtonSize.SMALL}
              />
            ) : (
              <ButtonIcon
                variant={ButtonVariant.PRIMARY}
                isLoading={isLoading}
                type={ButtonType.SUBMIT}
                icon={!isLoading ? <IconPaperPlane height="16" width="16" /> : <></>}
                className={cn({
                  '!absolute bottom-2 right-2': true,
                })}
                disabled={!isValid || !hasText || hasInputLimitError || isIngesting}
                buttonSize={ButtonSize.SMALL}
              />
            )}
          </form>
          {chatUploadEnabled && id && (
            <ButtonIcon
              disabled={isIngesting}
              className="bg-primary-cta text-on-primary h-[50px] w-auto"
              icon={<IconUploadArrow />}
              onClick={(e) => {
                e.stopPropagation();
                handleChatUploadClick(e);
              }}
            />
          )}
        </div>
      </div>
      <PoweredByDisclaimer
        className={isInputActive ? 'hidden sm:flex' : ''}
        currentChatAssistant={currentChatAssistant}
      />
    </div>
  );
};

export default ChatInput;
