'use client';
import { FC } from 'react';
import Routing from '@/components/Routing';

const App: FC = () => {
  return (
    <Routing />
  );
};

export default App;
