'use client';

import { BenchmarkStatusNumbers, REFRESH_INTERVAL_BENCHMARKING } from '@/helpers/benchmarking';
import {
  getUseChatBenchmarkingAbortQueryKey,
  useChatBenchmarkingAbortMutation,
  useChatBenchmarkingStatusQuery,
} from '@/lib/swr/hooks';
import { Tooltip } from '@unique/component-library';
import { Spinner } from '@unique/component-library/src/Spinner';
import { downloadFile, useToast, ToastVariant, LayoutContext } from '@unique/shared-library';
import cn from 'classnames';
import { FC, useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import BenchmarkingDropzone from './BenchmarkingDropzone';
import BenchmarkingResult from './BenchmarkingResult';
import { logger } from '@unique/next-commons/logger';
import { ExcelTemplateDownload } from './ExcelTemplateDownload';
import { ClientContext, Service } from '@unique/next-commons/swr';

const benchmarkLogger = logger.child({ module: 'ChatBenchmarking' });

export const ChatBenchmarking: FC = () => {
  const { setSplitPaneContent } = useContext(LayoutContext);
  const { services } = useContext(ClientContext);
  const chatBackendUrl = services[Service.NODE_CHAT];
  const [fileName, setFileName] = useState<string>();
  const [status, setStatus] = useState<string>();
  const [benchmarkId, setBenchmarkId] = useState<string>();
  const [benchmarkProcess, setBenchmarkProcess] = useState<BenchmarkStatusNumbers>({
    error: 0,
    done: 0,
    total: 0,
  });
  const [refreshInterval, setRefreshInterval] = useState<number>(0);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const auth = useAuth();
  const headers = {
    Authorization: `Bearer ${auth?.user.access_token}`,
  };
  const {
    data: chatBenchmarkingStatus,
    isLoading: isLoadingBenchmarkingStatus,
    isValidating: isValidatingBenchmarkingStatus,
    mutate,
  } = useChatBenchmarkingStatusQuery({ refreshInterval });

  useEffect(() => {
    setSplitPaneContent(null);
  }, [setSplitPaneContent]);

  const { trigger: abortBenchmark } = useChatBenchmarkingAbortMutation(
    getUseChatBenchmarkingAbortQueryKey(),
  );

  const { showToast } = useToast();

  useEffect(() => {
    if (isLoadingBenchmarkingStatus || isValidatingBenchmarkingStatus) return;
    setFileName(chatBenchmarkingStatus?.getCurrentStatus.filename);
    const { error, done, total, status, benchmarkId } = chatBenchmarkingStatus.getCurrentStatus;
    setBenchmarkProcess({ error, done, total });
    setStatus(status);
    setBenchmarkId(benchmarkId);
    if (error + done < total) {
      // Poll newest status every 20 seconds until its either done or failed
      setRefreshInterval(REFRESH_INTERVAL_BENCHMARKING);
    } else {
      setRefreshInterval(0);
    }
    return () => {
      setRefreshInterval(0);
    };
  }, [
    isLoadingBenchmarkingStatus,
    isValidatingBenchmarkingStatus,
    chatBenchmarkingStatus?.getCurrentStatus,
    showToast,
  ]);

  const handleUpload = async (acceptedFiles, fileRejections) => {
    if (!acceptedFiles.length || fileRejections.length) {
      showToast({ variant: ToastVariant.ERROR, message: 'Please upload a valid Excel file.' });
    }
    const body = new FormData();
    body.append('file', acceptedFiles[0]);
    const response = await fetch(chatBackendUrl + '/benchmarking', {
      method: 'POST',
      body,
      headers,
    });
    if (response.ok) {
      // immediately get the status again so that it updates properly in the UI
      mutate();
      // Poll newest status every 20 seconds until its either done or failed
      setRefreshInterval(REFRESH_INTERVAL_BENCHMARKING);
    } else {
      setBenchmarkProcess({ error: 0, done: 0, total: 0 });
      showToast({
        message: 'There was an error while uploading the file',
        variant: ToastVariant.ERROR,
      });
    }
  };

  const handleDownload = async () => {
    if (benchmarkProcess.error + benchmarkProcess.done !== benchmarkProcess.total) return;
    try {
      setIsDownloading(true);
      const response = await fetch(chatBackendUrl + '/benchmarking/action/download', {
        method: 'GET',
        headers,
      });
      if (!response.ok) {
        benchmarkLogger.error(
          `Error while downloading the file: ${response.statusText} body: ${await response.text()}`,
        );
        throw new Error(`Error while downloading the file: ${response.statusText}`);
      }
      response.blob().then((blob) => {
        // Download Excel
        downloadFile(blob, fileName);
        showToast({
          message: 'File downloaded successfully',
          variant: ToastVariant.SUCCESS,
        });
      });
    } catch (err) {
      showToast({
        message: 'There was an error while downloading the file',
        variant: ToastVariant.ERROR,
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleAbort = async () => {
    if (!benchmarkId) return;
    await abortBenchmark(
      { benchmarkId },
      {
        onSuccess: (data) => {
          console.log(data);
          setFileName(data?.abortBenchmark.filename);
          const { error, done, total, status } = data.abortBenchmark;
          setBenchmarkProcess({ error, done, total });
          setStatus(status);
        },
      },
    );
  };

  const isUploading = false; // todo

  return (
    <div className="mx-auto h-full w-full max-w-[1200px] items-center px-2 pt-6 md:p-4 md:pt-8">
      <h1 className="title-l text-on-background-main mb-2 text-left">Benchmarking</h1>
      <Tooltip
        wrapper={<BenchmarkingDropzone handleUpload={handleUpload} isUploading={isUploading} />}
        className={cn({
          'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 break-words text-center': true,
          block: isUploading,
          hidden: !isUploading,
        })}
        arrowPosition="none"
      >
        Benchmarking in progress. Upload will be available after completion.
      </Tooltip>

      <div className="my-7">
        <ExcelTemplateDownload chatBackendUrl={chatBackendUrl} />
      </div>

      {isLoadingBenchmarkingStatus && (
        <div className="mt-10 flex justify-center">
          <Spinner />
        </div>
      )}
      {!isLoadingBenchmarkingStatus && benchmarkId && (
        <BenchmarkingResult
          status={status}
          error={benchmarkProcess.error}
          done={benchmarkProcess.done}
          total={benchmarkProcess.total}
          filename={fileName}
          isDownloading={isDownloading}
          handleDownload={handleDownload}
          handleAbort={handleAbort}
        />
      )}
    </div>
  );
};
