'use client';

import {
  BoxInputEnum,
  InputSelect,
  InputSelectOption,
  MarkdownPreview,
  Spinner,
  TextBox,
} from '@unique/component-library';
import { supportedLanguages } from '@/helpers/translationTextHelpers';
import TranslationFeedback from './TranslationFeedback';
import { ClientThemeContext, useScreens } from '@unique/shared-library';
import { TranslationResult } from './TranslationComponent';
import { useContext } from 'react';
import { formatSupportEmail } from '@/helpers/formatSupportEmail';

type Props = {
  config: BoxInputEnum;
  result: TranslationResult;
  isLoading: boolean;
  className?: string;
  selectedOption: InputSelectOption;
  handleSelectOption: (option: InputSelectOption) => void;
};

const TranslationBoxView = ({
  config,
  className,
  result,
  isLoading,
  selectedOption,
  handleSelectOption,
}: Props) => {
  const { isMobile } = useScreens();
  const { supportEmail } = useContext(ClientThemeContext);

  return (
    <div className="flex h-full w-full flex-col">
      <div className="relative flex items-center justify-between md:mt-0">
        <div className="mb-3 mt-3 hidden sm:mt-0 sm:block">
          <InputSelect
            options={supportedLanguages.map((lang) => {
              return { label: lang, value: lang };
            })}
            handleSelectOption={handleSelectOption}
            selectedOption={selectedOption}
            id="translate"
          />
        </div>
      </div>
      {result.text?.startsWith('[SYSTEM]') ? (
        <div className="bg-background h-full overflow-auto rounded-lg">
          <MarkdownPreview
            text={formatSupportEmail(result.text, supportEmail)}
            className="border-background bg-background text-on-background-main border py-3"
          />
        </div>
      ) : (
        <TextBox
          placeholder={isLoading ? '' : 'Translation'}
          loader={
            isLoading && (
              <div className="text-on-background-dimmed flex">
                <Spinner />
                &nbsp;Translating...
              </div>
            )
          }
          showClearButton={false}
          value={isLoading ? '' : result.text}
          setValue={() => null}
          config={config}
          onChange={() => null}
          className={className}
          actions={result.id && !isLoading && !isMobile && <TranslationFeedback result={result} />}
        />
      )}
    </div>
  );
};

export default TranslationBoxView;
