import { AnalyticsOrderState } from '@/@generated/graphql';

export const getAnalyticsOrderStateText = (orderState: AnalyticsOrderState) => {
  switch (orderState) {
    case AnalyticsOrderState.Created:
      return 'Request created';
    case AnalyticsOrderState.Running:
      return 'Request processing';
    case AnalyticsOrderState.Done:
      return 'Done';
    case AnalyticsOrderState.Error:
      return 'Error';
    default:
      return 'Unknown';
  }
};
