import { AssistantQuery } from '@/@generated/graphql';
import { SpaceInfo } from '../Space/SpaceInfo';
import { ButtonIcon, InputField } from '@unique/component-library';
import { IconFileAdd } from '@unique/icons';
import { Link, useLocation } from 'react-router-dom';
import { useDueDiligenceQuery } from '@/lib/swr/hooks';
import DueDiligenceList from './DueDiligenceList';

export default function DueDiligenceHome({
  assistant,
}: {
  assistant: AssistantQuery['assistantByUser'];
}) {
  const location = useLocation();
  const { data: dueDiligenceList, isLoading } = useDueDiligenceQuery();

  return (
    <div>
      {assistant?.explanation || assistant?.alert ? (
        <div className="bg-background flex items-center justify-center py-5">
          <div className="mx-auto max-w-[928px] px-3 sm:px-0">
            <SpaceInfo
              explanation={assistant?.explanation}
              alert={assistant?.alert}
              bg="bg-surface"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="mx-auto mt-5 max-w-[928px] px-3 sm:px-0">
        <div className="bg-surface">
          <div className="flex w-full items-center justify-between gap-3 px-0 sm:px-2">
            <InputField name="search" placeholder="Search sheet" type="text" />
            <Link to={location.pathname + '/create-worksheet?step=import-questions'}>
              <ButtonIcon icon={<IconFileAdd />} className="bg-primary-cta">
                New&nbsp;Sheet
              </ButtonIcon>
            </Link>
          </div>
          <div className="mt-10">
            <DueDiligenceList dueDiligence={dueDiligenceList?.dueDiligence} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>
  );
}
