'use client';
import {
  AddMagicTableDocumentsDocument,
  ImportMagicTableColumnsDocument,
} from '@/@generated/graphql';
import {
  useAddMagicTableDocumentsMutation,
  useContentByChatQuery,
  useImportMagicTableColumns,
  usePaginatedContentQuery,
} from '@/lib/swr/hooks';
import { ButtonIcon, ButtonVariant, InputField, Spinner } from '@unique/component-library';
import { FC, useState } from 'react';
import FileAccordion from './FileAccordion';
import { useNavigate } from 'react-router-dom';

type Props = {
  assistantId: string;
  sourceContentIds: string[];
  chatId: string;
  magicTableSheetId: string;
  importStepContentId?: string;
};

const CreateDueDiligenceBox: FC<Props> = ({
  assistantId,
  sourceContentIds,
  chatId,
  magicTableSheetId,
  importStepContentId,
}) => {
  const navigate = useNavigate();

  const [fileName, setFileName] = useState<string>('Untitled Sheet');
  const { data: chatContent, isLoading: isLoadingChatContent } = useContentByChatQuery({
    chatId,
  });

  const { data: sourceContent, isLoading: isLoadingSourceContent } = usePaginatedContentQuery(
    { where: { id: { in: sourceContentIds } } },
    { revalidateOnFocus: false },
  );

  const { data: importStepContent, isLoading: isLoadingImportStepContent } =
    usePaginatedContentQuery(
      importStepContentId ? { where: { id: { equals: importStepContentId } } } : null,
      { revalidateOnFocus: false },
    );

  const { trigger: addMagicTable, isMutating: addingMagicTable } =
    useAddMagicTableDocumentsMutation({
      variables: {},
      query: AddMagicTableDocumentsDocument,
    });

  const { trigger: importMagicTableColumns, isMutating: importingColumns } =
    useImportMagicTableColumns({
      variables: {},
      query: ImportMagicTableColumnsDocument,
    });

  const handleCreateTable = async () => {
    const resp = await addMagicTable({ contentIds: sourceContentIds, sheetId: magicTableSheetId });
    if (resp.addMagicTableDocuments.status) {
      const resp = await importMagicTableColumns({
        sheetId: magicTableSheetId,
        contentId: importStepContentId ? importStepContentId : chatContent?.contentByChat[0]?.id,
        sheetName: fileName,
      });
      if (resp.importMagicTableColumns.status) {
        navigate(`/space/${assistantId}/${magicTableSheetId}`);
      }
    }
  };

  const getContent = () => {
    if (importStepContentId) {
      return importStepContent?.paginatedContent?.nodes;
    }
    return chatContent?.contentByChat && chatContent?.contentByChat?.length > 0
      ? chatContent?.contentByChat
      : [];
  };

  const isLoading = importingColumns || addingMagicTable;

  const numOfSources = sourceContent?.paginatedContent?.nodes?.length || 0;
  return (
    <div className="mx-auto mt-10 flex max-w-7xl justify-center px-5">
      <div className="flex w-full max-w-3xl">
        <div className="flex w-full flex-col gap-5">
          <div className="flex w-full gap-5">
            <NumberBox digit={1} />
            <FileAccordion
              buttonTitle="Questions"
              content={getContent()}
              isLoading={isLoadingChatContent || isLoadingImportStepContent}
            />
          </div>
          <div className="flex w-full gap-5">
            <NumberBox digit={2} />
            <FileAccordion
              buttonTitle={`${numOfSources} Source${numOfSources > 1 ? 's' : ''}`}
              content={sourceContent?.paginatedContent?.nodes}
              isLoading={isLoadingSourceContent}
            />
          </div>
          <div className="flex w-full gap-2">
            <div className="flex items-start gap-5">
              <NumberBox digit={3} />
              <div className="flex flex-col gap-2">
                <p className="text-on-background-main font-extrabold">Sheet Name</p>
                <div className="w-full sm:w-[500px] md:w-[710px]">
                  <InputField
                    autoFocus
                    name="fileName"
                    classes="w-full"
                    value={fileName}
                    handleChange={(e) => setFileName(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 flex w-full justify-end">
            <ButtonIcon
              variant={ButtonVariant.PRIMARY}
              onClick={handleCreateTable}
              icon={isLoading ? <Spinner size={16} wrapperClasses="pt-1" /> : null}
              disabled={isLoading}
            >
              Create Table
            </ButtonIcon>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDueDiligenceBox;

const NumberBox = ({ digit }: { digit: number }) => {
  return (
    <div className="bg-primary-cta text-on-primary flex h-10 w-10 items-center justify-center rounded-md font-extrabold">
      {digit}
    </div>
  );
};
