export const extractUniqueContentIdsForImages = (markdown: string): string[] => {
  const regex = /!\[image\]\(unique:\/\/content\/(.*?)\)/g;
  const matches = [];
  let match;

  while ((match = regex.exec(markdown)) !== null) {
    matches.push(match[1]);
  }
  return matches;
};
