import {
  BoxInputEnum,
  ButtonIcon,
  ButtonVariant,
  InputSelectOption,
  TextBox,
} from '@unique/component-library';
import { IconPaperPlane } from '@unique/icons';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  config: BoxInputEnum;
  handleTranslate: () => void;
  text: string;
  setText: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  selectedOption: InputSelectOption;
  className?: string;
  onClearButtonClicked: () => void;
};

const TranslationBoxInput = ({
  config,
  className,
  handleTranslate,
  text,
  setText,
  isLoading,
  selectedOption,
  onClearButtonClicked,
}: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };
  return (
    <div className="flex h-full w-full flex-col">
      <div className="mb-3 hidden justify-between sm:flex">
        <ButtonIcon variant={ButtonVariant.SECONDARY} className="pointer-events-none !font-medium">
          Original Text
        </ButtonIcon>
        <ButtonIcon variant={ButtonVariant.SECONDARY} className="pointer-events-none !font-medium">
          &rarr;
        </ButtonIcon>
      </div>
      <TextBox
        placeholder="Enter your text"
        value={text}
        setValue={setText}
        config={config}
        className={className}
        onChange={handleChange}
        onClearButtonClicked={onClearButtonClicked}
        actions={
          <div className="hidden sm:block">
            <ButtonIcon
              disabled={isLoading || !text}
              icon={<IconPaperPlane height="14" width="14" />}
              onClick={handleTranslate}
            >
              Translate to {selectedOption.label}
            </ButtonIcon>
          </div>
        }
      />
    </div>
  );
};

export default TranslationBoxInput;
