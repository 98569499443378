import { IconCheckmark } from '@unique/icons';
import cn from 'classnames';

export enum StepStatus {
  upcoming = 'upcoming',
  current = 'current',
  complete = 'complete',
}
type Props = {
  steps: { id: string; title: string; href: string; status: string }[];
};

export default function NavigationStepper({ steps }: Props) {
  const Title = ({ title, status }: { title: string; status: string }) => (
    <span
      className={cn({
        'whitespace-wrap ml-12 mr-2 font-extrabold md:whitespace-nowrap': true,
        'text-on-control-dimmed': status === StepStatus.upcoming,
        '!text-on-background-main': status === StepStatus.complete || status === StepStatus.current,
      })}
    >
      {title}
    </span>
  );

  return (
    <nav aria-label="Progress">
      <ol role="list" className="mr-28 flex w-[400px] gap-x-5 md:w-[700px]">
        {steps.map((step, stepIdx) => (
          <li
            key={step.id}
            className={cn(stepIdx !== steps.length - 1 ? 'w-full pr-8 sm:pr-20' : '', 'relative')}
          >
            {step.status === StepStatus.complete ? (
              <>
                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                  <Title status={step.status} title={step.title} />
                  <div className="bg-primary-cta h-0.5 flex-1" />
                </div>
                <a
                  href="#"
                  className="bg-primary-cta hover:bg-primary-cta text-on-primary relative flex h-10 w-10 items-center justify-center rounded-md"
                >
                  <IconCheckmark aria-hidden="true" className="h-5 w-5" />
                </a>
              </>
            ) : step.status === StepStatus.current ? (
              <>
                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                  <Title status={step.status} title={step.title} />
                  <div className="bg-primary-cta h-0.5 flex-1" />
                </div>
                <a
                  href="#"
                  aria-current="step"
                  className="bg-primary-cta border-primary-cta relative flex h-10 w-10 items-center justify-center rounded-md border-2"
                >
                  <span
                    aria-hidden="true"
                    className="group-hover:bg-primary-cta text-on-primary text-xl font-extrabold"
                  >
                    {step.id}
                  </span>
                </a>
              </>
            ) : (
              <>
                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                  <Title status={step.status} title={step.title} />

                  <div className="bg-background-variant h-0.5 flex-1" />
                </div>
                <a
                  href="#"
                  className="bg-background-variant border-background-variant group relative flex h-10 w-10 items-center justify-center rounded-md border-2"
                >
                  <span className="group-hover:bg-background-variant text-on-primary text-xl font-extrabold">
                    {step.id}
                  </span>
                </a>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
