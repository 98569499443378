'use client';
import { extractPagesFromName, hidePagesFromName } from '@/helpers/pdfPreview';
import { Reference } from '@/lib/swr/types';
import { OpenFile, PageChangeEvent, SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { searchPlugin } from '@react-pdf-viewer/search';
import {
  toolbarPlugin,
  type ToolbarSlot,
  type TransformToolbarSlot,
} from '@react-pdf-viewer/toolbar';
import { SecondLevelNavItem } from '@unique/component-library';
import { IconClose, IconDownload, IconFileNotFound, IconNewWindow } from '@unique/icons';
import { ClientContext, Service } from '@unique/next-commons/swr';
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';
import { FC, useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

type PDFPreviewProps = {
  reference: Reference;
  handleOpenReferenceUrl: (reference: Reference) => void;
  handleClosePdfPreview: () => void;
  chatId: string;
};

export const PDFPreview: FC<PDFPreviewProps> = ({
  reference,
  handleOpenReferenceUrl,
  handleClosePdfPreview,
  chatId,
}) => {
  const [pdfLoaderReady, setPdfLoaderReady] = useState(false);
  const [documentLoaded, setDocumentLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>();
  const [objectUrl, setObjectUrl] = useState<string>();
  const { services } = useContext(ClientContext);
  const auth = useAuth();

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const toolbarPluginInstance = toolbarPlugin({
    getFilePlugin: {
      fileNameGenerator: (file: OpenFile) => {
        if (reference.name) {
          return hidePagesFromName(reference.name).trim();
        }
        return file.name;
      },
    },
  });
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: () => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>,
    // hide sidebar tabs
    sidebarTabs: () => [],
  });

  // hide specific toolbar actions that are not needed (e.g. open, print etc.)
  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    DownloadMenuItem: () => <></>,
    EnterFullScreen: () => <></>,
    SwitchTheme: () => <></>,
    Print: () => <></>,
    Open: () => <></>,
    Download: () => <></>,
  });

  const searchPluginInstance = searchPlugin({
    keyword: [/^(.*)$/],
  });

  useEffect(() => {
    const loadPdf = async () => {
      const pdfjs = await import('pdfjs-dist/build/pdf');
      const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');
      pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
      setPdfLoaderReady(true);
    };
    loadPdf();
  }, []);

  useEffect(() => {
    const fetchPreviewPdfFromApi = async () => {
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${auth.user.access_token}`);

      const response = await fetch(
        `${services[Service.NODE_INGESTION]}/v1/content/${encodeURIComponent(
          reference.contentId,
        )}/preview-file?chatId=${encodeURIComponent(chatId)}`,
        { headers },
      );
      const url = window.URL.createObjectURL(await response.blob());

      setObjectUrl(url);
    };
    fetchPreviewPdfFromApi();
  }, []);

  const onClickChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
    pageNavigationPluginInstance.jumpToPage(pageNumber);
  };

  useEffect(() => {
    if (!reference) return;
    const referencePages = extractPagesFromName(reference.name);
    setCurrentPage(referencePages[0] && referencePages[0] >= 1 ? referencePages[0] - 1 : 0);
    searchPluginInstance.setTargetPages(
      (targetPage) => referencePages && referencePages.includes(targetPage.pageIndex + 1),
    );
    // This side effect should only run once, if searchPluginInstance is included
    // in the dependency array it will rerun whenever user interacts with the pdf
  }, [reference]);

  const handlePageChange = useDebouncedCallback((e: PageChangeEvent) => {
    setCurrentPage(e.currentPage);
  });
  // TODO: remove when the issue is fixed https://github.com/react-pdf-viewer/react-pdf-viewer/issues/1611
  useEffect(() => {
    // Function to modify the style of popovers
    const modifyPopoversStyle = () => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      const popovers = document.querySelectorAll(
        '[id^="rpv-core__popover-body-inner"]',
      ) as NodeListOf<HTMLElement>;
      popovers.forEach((popover) => {
        if (popover.style.maxHeight === '0px') {
          popover.style.maxHeight = 'none'; // Unset the max-height
        }
      });
    };

    // Create a new MutationObserver instance
    const observer = new MutationObserver((mutations) => {
      if (mutations.some((mutation) => mutation.addedNodes.length)) {
        modifyPopoversStyle();
      }
    });

    // Start observing the document body for added nodes
    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup function
    return () => observer.disconnect();
  }, []);

  const referencePages = extractPagesFromName(reference.name);

  const renderError = () => {
    return (
      <div className="flex flex-col items-center justify-center py-20 sm:h-full">
        <div className="text-primary-cta">
          <IconFileNotFound />
        </div>
        <div className="text-on-background-main mt-5 max-w-[320px] text-center text-sm">
          This file was removed from the Knowledge Base and it can no longer be previewed.
        </div>
      </div>
    );
  };

  const isPdfFile = reference.mimeType === 'application/pdf';

  return (
    <div className="bg-secondary fixed left-0 top-0 z-[60] flex h-full w-full flex-col md:relative">
      <div className="text-on-secondary pt-2">
        <div className="my-4 flex w-full items-center gap-x-3 px-4">
          <div className="subtitle-1">Reference</div>
          <div className="flex items-center gap-x-1">
            <div className="subtitle-2 bg-background-variant text-on-background-main group-hover:bg-attention-variant group-hover:text-on-attention-variant flex h-4 w-4 items-center justify-center rounded-md transition">
              {reference.sequenceNumber}
            </div>
            <div className="subtitle-1">:</div>
          </div>
          <div className="subtitle-1 truncate">{hidePagesFromName(reference.name)}</div>
          <div className="ml-auto flex gap-x-4">
            <a
              className="cursor-pointer"
              onClick={(event) => {
                event.preventDefault();
                handleOpenReferenceUrl ? handleOpenReferenceUrl(reference) : null;
              }}
            >
              {/* pdf files are opened in the browser, while other file types get downloaded */}
              {isPdfFile ? <IconNewWindow /> : <IconDownload />}
            </a>
            <div className="cursor-pointer" onClick={handleClosePdfPreview}>
              <IconClose width="16px" height="16px" />
            </div>
          </div>
        </div>
        <div className="global-scrollbar flex w-full overflow-auto">
          {referencePages?.map((pageNumber) => (
            <div key={`page${pageNumber}`}>
              <SecondLevelNavItem
                name={`p. ${pageNumber}`}
                onClick={() => onClickChangePage(pageNumber - 1)}
                isActive={currentPage + 1 === pageNumber}
                className={!documentLoaded ? 'pointer-events-none !opacity-50' : ''}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="bg-background flex-1 overflow-auto">
        {(pdfLoaderReady && objectUrl) && (
            <Viewer
              theme="dark"
              key={`pdf-preview-${reference.id}`}
              initialPage={currentPage}
              fileUrl={objectUrl}
              onDocumentLoad={() => setDocumentLoaded(true)}
              defaultScale={SpecialZoomLevel.PageWidth}
              onPageChange={(e: PageChangeEvent) => handlePageChange(e)}
              plugins={[
                defaultLayoutPluginInstance,
                searchPluginInstance,
                toolbarPluginInstance,
                pageNavigationPluginInstance,
              ]}
              renderError={renderError}
            />
          )}
      </div>
    </div>
  );
};

export default PDFPreview;
