import { useState } from 'react';
import { ContentById } from '@/lib/swr/types';
import { ContentItem } from './ContentItem';
import { ButtonIcon, getInitials } from '@unique/component-library';
import { IconArrowDown } from '@unique/icons';
import cn from 'classnames';
import { useAuth } from 'react-oidc-context';
import ContentWrapper from './ContentWrapper';
import { isIngestingContent, isIngestionFailed } from '@unique/shared-library';
import FileUploadDetails from './FileUploadDetails';

interface Props {
  content: ContentById[];
  handleFileOpenClick: (contentItem: ContentById) => void;
  loadImageURLFromContent: (contentItem: ContentById) => Promise<string>;
}

export default function ContentList({
  content,
  handleFileOpenClick,
  loadImageURLFromContent,
}: Props) {
  const [showAllFiles, setShowAllFiles] = useState(true);
  const {
    user: { profile },
  } = useAuth();
  const initials = getInitials(profile.name, profile.email);
  const contentWithError = content.filter((c) => isIngestionFailed(c.ingestionState));
  const ingestionFailedOrIngesting =
    content.length > 1 &&
    content.every(
      (c) => isIngestionFailed(c.ingestionState) || isIngestingContent(c.ingestionState),
    );

  const isImage = (content: ContentById): boolean => {
    return content.mimeType.startsWith('image/');
  };

  const showAllFilesHandler = () => {
    setShowAllFiles(!showAllFiles);
  };

  if (content.length === 1) {
    return (
      <ContentItem
        content={content[0]}
        handleFileOpenClick={handleFileOpenClick}
        loadImageURLFromContent={loadImageURLFromContent}
        initials={initials}
      />
    );
  }

  if (content.every(isImage)) {
    return (
      <div>
        {content.map((contentItem) => (
          <ContentItem
            key={contentItem.id}
            content={contentItem}
            handleFileOpenClick={handleFileOpenClick}
            loadImageURLFromContent={loadImageURLFromContent}
            initials={initials}
          />
        ))}
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col items-start gap-2">
      <ContentWrapper fullDetails={true} initials={initials} className="mt-2">
        <div className="flex items-center gap-3">
          <ButtonIcon
            onClick={showAllFilesHandler}
            className="!bg-background !text-on-background-dimmed hover:!bg-background-variant hover:!text-on-background-main mt-2 !h-[28px] w-fit !px-2"
          >
            <span className="flex items-center gap-x-3">
              <span className="inline">{content.length} file uploads</span>
              <IconArrowDown
                className={cn({
                  'transition duration-200': true,
                  'rotate-180': showAllFiles,
                })}
                width="14px"
                height="14px"
              />
            </span>
          </ButtonIcon>
          {contentWithError.length > 0 && (
            <span className="bg-error-light text-on-error-light mt-2 rounded-lg px-2 font-bold">
              {contentWithError.length} Error{contentWithError.length > 1 ? 's' : ''}
            </span>
          )}
        </div>
        {/* All file uploads card */}
        <div className={cn({ 'flex w-full flex-col gap-2': true, hidden: !showAllFiles })}>
          <div className="border-background mt-3 w-full overflow-hidden bg-surface rounded-lg border">
            {content.map((contentItem) => (
              <ContentItem
                key={contentItem.id}
                loadImageURLFromContent={loadImageURLFromContent}
                content={contentItem}
                handleFileOpenClick={handleFileOpenClick}
                fullDetails={false}
                initials={initials}
              />
            ))}
          </div>
          <div className="flex justify-end">
            {!ingestionFailedOrIngesting && <FileUploadDetails content={content[0]} />}
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
}
