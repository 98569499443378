import { useAppDispatch, setLastSpaceIdVisited } from '@/store';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

/**
 * This component is used to set the last space id visited in the store
 */
const SpaceWrapper: React.FC = () => {
    const dispatch = useAppDispatch();
    const { spaceId } = useParams();

    useEffect(() => {
        if (spaceId) {
            dispatch(setLastSpaceIdVisited(spaceId));
        }
    }, [spaceId, dispatch]);

    return <Outlet />;
};

export default SpaceWrapper;