import { ContentByIdQuery, getSdk } from '@/@generated/graphql';
import {
  isInternalUniqueUrl,
  mapReadUrlForReferences,
  parseContentIdFromInternalUrl,
} from '@/helpers/references';
import { useContentByIdQuery } from '@/lib/swr/hooks';
import { Reference } from '@/lib/swr/types';
import { loadFile } from '@unique/next-commons/helpers';
import { logger } from '@unique/next-commons/logger';
import { ClientContext, Service } from '@unique/next-commons/swr';
import { LayoutContext, ToastVariant, useToast } from '@unique/shared-library';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { FC, useCallback, useContext, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import ChatReferenceContent from './ChatReferenceContent';
import PDFPreview from './PDFPreview';

const log = logger.child({
  package: 'chat',
  namespace: 'components:chat:ChatReference',
});

interface ChatReferencesProps {
  references?: Reference[];
  showPdfHighlighting?: boolean;
  redirectInternalStorageOnly?: boolean;
}

export const ChatReferences: FC<ChatReferencesProps> = ({
  references,
  showPdfHighlighting,
  redirectInternalStorageOnly,
}) => {
  const { id } = useParams<{ id: string }>();
  const auth = useAuth();
  const { setIsMenuExpanded, setSplitPaneContent } = useContext(LayoutContext);
  // Store reference id currently loading from handleOpenReferenceUrl function
  const [loadingReference, setLoadingReference] = useState(null);
  const { showToast } = useToast();
  const { clients, services } = useContext(ClientContext);
  if (!references) references = [];

  const referencesToRewriteUrl = references.filter((reference) =>
    isInternalUniqueUrl(reference.url),
  );

  const { data: contentData } = useContentByIdQuery({
    contentIds: referencesToRewriteUrl.map((reference) =>
      parseContentIdFromInternalUrl(reference.url),
    ),
    chatId: id,
  });

  const className =
    'subtitle-2 px-2 py-2 flex items-center justify-center min-h-[40px] text-on-background-dimmed bg-background rounded-lg mr-5 last:ml-0 mt-4 relative';

  const sortedReferencesWithReadUrl = mapReadUrlForReferences(
    references,
    contentData,
    redirectInternalStorageOnly,
  ).sort((a, b) => a.sequenceNumber - b.sequenceNumber);

  const handleClosePdfPreview = useCallback(() => {
    setSplitPaneContent(null);
  }, [setSplitPaneContent]);

  const handleOpenReferenceUrl = useCallback(
    async (reference: Reference) => {
      if (!isInternalUniqueUrl(reference.url)) {
        window.open(reference.url, '_blank', 'noopener noreferrer');
        return;
      }

      // Set reference as loading to show spinner in template
      setLoadingReference(reference.id);
      try {
        // Request content object
        const sdk = getSdk(clients[Service.NODE_INGESTION]);
        const query: ContentByIdQuery = await sdk.ContentById({
          contentIds: parseContentIdFromInternalUrl(reference.url),
          chatId: id,
        });
        const content = query.contentById[0];

        if (redirectInternalStorageOnly || !content.url) {
          try {
            await loadFile({
              accessToken: auth.user.access_token,
              ingestionUrl: services[Service.NODE_INGESTION],
              content,
              chatId: id,
              shouldOpen: true,
            });
          } catch {
            showToast({
              message: `Can not open file ${content.title || content.key}`,
              variant: ToastVariant.ERROR,
            });
          }
        } else {
          window.open(content.url, '_blank', 'noopener noreferrer');
        }
      } catch (error) {
        showToast({
          message: `Reference ${reference.name} can't be opened.`,
          variant: ToastVariant.ERROR,
          duration: 6000,
        });
        log.error(error);
      } finally {
        setLoadingReference(null);
      }
    },
    [id, redirectInternalStorageOnly, showToast],
  );

  const handleClickReference = useCallback(
    (reference) => {
      // Only show PDF preview for internal files (e.g. files that have a content entry).
      // For external files (e.g. Sharepoint), CORS is preventing us from showing the preview
      if ((!!reference.pdfPreviewName || !!reference.pdfPreviewUrl) && showPdfHighlighting) {
        setSplitPaneContent(
          <PDFPreview
            key={reference.id}
            reference={reference}
            chatId={id}
            handleOpenReferenceUrl={handleOpenReferenceUrl}
            handleClosePdfPreview={handleClosePdfPreview}
          />,
        );
        setIsMenuExpanded(false);
      } else {
        handleOpenReferenceUrl(reference);
      }
    },
    [
      showPdfHighlighting,
      setIsMenuExpanded,
      setSplitPaneContent,
      handleClosePdfPreview,
      handleOpenReferenceUrl,
    ],
  );

  return (
    <div>
      <div className="overline-text text-on-background-dimmed">References:</div>
      <div className="flex flex-wrap">
        {sortedReferencesWithReadUrl.map((reference) =>
          reference.url ? (
            <a
              onClick={(event) => {
                event.preventDefault();
                handleClickReference(reference);
              }}
              target="_blank"
              rel="noopener noreferrer"
              key={reference.id}
              className={cn({
                [className]: true,
                '!cursor-default opacity-50': reference.id === loadingReference,
                'hover:bg-background-variant hover:text-on-surface group max-w-[230px] flex-auto cursor-pointer transition-all':
                  true,
              })}
            >
              <ChatReferenceContent
                reference={reference}
                isLoading={reference.id === loadingReference}
                isInternalPdfReference={!!reference.pdfPreviewUrl || !!reference.pdfPreviewName}
                showPdfHighlighting={showPdfHighlighting}
              />
            </a>
          ) : (
            <div key={reference.id} className={className}>
              <ChatReferenceContent reference={reference} />
            </div>
          ),
        )}
      </div>
    </div>
  );
};
