import { differenceInCalendarDays } from 'date-fns';

export function categorizeDate(createdAt: Date) {
  const today = new Date();
  const daysAgo = differenceInCalendarDays(today, new Date(createdAt));

  if (daysAgo === 0) {
    return 'today';
  } else if (daysAgo === 1) {
    return 'yesterday';
  } else if (daysAgo <= 7) {
    return 'sevenDays';
  } else if (daysAgo <= 30) {
    return 'thirtyDays';
  } else {
    return 'older';
  }
}
