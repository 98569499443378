import {
  ButtonIcon,
  ButtonSize,
  ButtonVariant,
  Tooltip,
  TooltipSize,
} from '@unique/component-library';
import { IconBook } from '@unique/icons';
import { FC } from 'react';

type SavePromptControlProps = {
  onSavePromptClick: (prompt: string) => void;
  prompt: string;
  iconOnly?: boolean;
};

export const SavePromptControl: FC<SavePromptControlProps> = ({
  prompt,
  onSavePromptClick,
  iconOnly = false,
}) => {
  if (iconOnly) {
    return (
      <div className="absolute bottom-2 right-0 group-hover:block">
        <Tooltip
          wrapper={
            <ButtonIcon
              icon={<IconBook />}
              onClick={() => onSavePromptClick(prompt)}
              buttonSize={ButtonSize.SMALL}
              variant={ButtonVariant.SECONDARY}
              className="!bg-background-variant !text-on-background-main"
            />
          }
          arrowPosition="none"
          size={TooltipSize.SMALL}
          className="!right-10 !top-1 z-auto !mt-0 h-6 whitespace-nowrap"
        >
          Add to My Prompts
        </Tooltip>
      </div>
    );
  }
  return (
    <div className="absolute bottom-2 right-2 hidden group-hover:block">
      <ButtonIcon
        icon={<IconBook />}
        className="!ml-auto !flex"
        onClick={() => onSavePromptClick(prompt)}
        variant={ButtonVariant.SECONDARY}
        buttonSize={ButtonSize.SMALL}
      >
        Save
      </ButtonIcon>
    </div>
  );
};
