import IndeterminateCheckbox from '@unique/component-library/src/IndeterminateCheckbox';

export const FeedbackCheckbox = ({
  checked,
  setChecked,
  id,
  text,
}: {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  id: string;
  text: string;
}) => {
  return (
    <div className="group mb-4 leading-4">
      <IndeterminateCheckbox onChange={() => setChecked(!checked)} id={id} checked={checked} />
      <label htmlFor={id} className="body-2 text-on-background-main ml-2 cursor-pointer">
        {text}
      </label>
    </div>
  );
};
