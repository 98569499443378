'use client';
import { useCallback, useEffect, useState } from 'react';
import NavigationStepper, { StepStatus } from '../NavigationStepper';
import { IconImportDocuments, IconUploadInChat } from '@unique/icons';
import DueDiligenceUploadBox from '../DueDiligence/DueDiligenceUploadBox';
import CreateDueDiligenceBox from '../DueDiligence/CreateDueDiligenceBox';
import { FolderPathSelection } from '@unique/shared-library';
import DueDiligenceAddSource from '../DueDiligence/DueDiligenceAddSource';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { CreateMagicTableSheetMutation, getSdk } from '@/@generated/graphql';
import { clientContextValues } from '@unique/next-commons/swr';

const initialSteps = [
  { id: '1', title: 'Import Questions', status: StepStatus.upcoming, href: 'import-questions' },
  { id: '2', title: 'Add Sources', status: StepStatus.upcoming, href: 'add-sources' },
  { id: '3', title: 'Create Table', status: StepStatus.upcoming, href: 'create-table' },
];

export default function CreateDueDiligence() {
  const { spaceId: assistantId } = useParams();

  const [steps, setSteps] = useState(initialSteps);
  const [folderPathModalTitle, setFolderPathModalTitle] = useState('');
  const [showFolderPathSelectionModal, setShowFolderPathSelectionModal] = useState(false);
  const [sourceContentIds, setSourceContentIds] = useState<string[]>([]);
  const [chatId, setChatId] = useState<string>('chat_lhi5drjv4hmq16pjlv01ed2n');
  const [magicTableSheetId, setMagicTableSheetId] = useState<string>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [importStepContentId, setImportStepContentId] = useState<string>();

  useEffect(() => {
    if (!searchParams.get('step')) {
      const newSteps = [...steps];
      newSteps[0].status = StepStatus.current;
      setSteps(newSteps);
      return;
    }
    const newSteps = [...steps];
    if (searchParams.get('step') === initialSteps[0].href) {
      newSteps[0].status = StepStatus.current;
    }
    if (searchParams.get('step') === initialSteps[1].href) {
      newSteps[0].status = StepStatus.complete;
      newSteps[1].status = StepStatus.current;
    }

    if (searchParams.get('step') === initialSteps[2].href) {
      newSteps[0].status = StepStatus.complete;
      newSteps[1].status = StepStatus.complete;
      newSteps[2].status = StepStatus.current;
    }
    setSteps(newSteps);
  }, [searchParams]);

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams],
  );

  const goToNextPage = () => {
    const currentStepIndex = steps.findIndex((step) => searchParams.get('step') === step.href);

    const newSteps = steps.map((step, index) => {
      if (index === currentStepIndex) {
        return { ...step, status: StepStatus.complete };
      }

      if (index === currentStepIndex + 1) {
        return { ...step, status: StepStatus.current };
      }

      return step;
    });

    const currentStep = steps[currentStepIndex + 1];

    navigate(
      `/space/${assistantId}/create-worksheet?${createQueryString('step', currentStep.href)}`,
    );
    setSteps(newSteps);
  };

  const onActionButtonClicked = (id, chat_id) => {
    if (searchParams.get('step') === initialSteps[0].href && id && chat_id) {
      // Upload Due Diligence questions is done
      setChatId(chat_id);
      setMagicTableSheetId(id);
      goToNextPage();
    }
    if (searchParams.get('step') === initialSteps[1].href) {
      // Add Sources
      setShowFolderPathSelectionModal(true);
      setFolderPathModalTitle('Select Files or Folders to add');
    }
  };

  const handleFolderPathSelected = async ({ folderIds }) => {
    setShowFolderPathSelectionModal(false);
    goToNextPage();

    if (searchParams.get('step') === initialSteps[0].href) {
      // Create Magic Table
      const sdkDefault = getSdk(clientContextValues.defaultClient);
      const createMagicTableSheetQuery: CreateMagicTableSheetMutation =
        await sdkDefault.CreateMagicTableSheet({
          assistantId,
        });
      const { id, chatId } = createMagicTableSheetQuery.createMagicTableSheet;
      setImportStepContentId(folderIds[0]);
      setChatId(chatId);
      setMagicTableSheetId(id);
    }

    if (searchParams.get('step') === initialSteps[1].href) {
      setSourceContentIds(folderIds.filter((id) => id.startsWith('cont_')));
    }
  };

  const handleShowFolderPathSelection = () => {
    setFolderPathModalTitle('Select 1 File');
    setShowFolderPathSelectionModal(true);
  };

  const currentStep = searchParams.get('step');
  return (
    <>
      <div className="flex flex-col">
        <div className="bg-background flex items-center justify-center py-5">
          <NavigationStepper steps={steps} />
        </div>
        {currentStep === initialSteps[0].href ? (
          <DueDiligenceUploadBox
            assistantId={assistantId}
            onSubmit={onActionButtonClicked}
            icon={<IconImportDocuments />}
            title=" Import Due Diligence questions from document"
            description="PDF, DOCx, CSV"
            handleShowFolderPathSelection={handleShowFolderPathSelection}
          />
        ) : null}
        {currentStep === initialSteps[1].href ? (
          <DueDiligenceAddSource
            assistantId={assistantId}
            onSubmit={onActionButtonClicked}
            icon={<IconUploadInChat />}
            title="Add Sources of Company Information"
            description="PDF, DOCx, CSV"
            uploadButtonTitle="Add Files"
          />
        ) : null}
        {currentStep === initialSteps[2].href ? (
          <CreateDueDiligenceBox
            assistantId={assistantId}
            sourceContentIds={sourceContentIds}
            chatId={chatId}
            magicTableSheetId={magicTableSheetId}
            importStepContentId={importStepContentId}
          />
        ) : null}
      </div>
      {showFolderPathSelectionModal && (
        <FolderPathSelection
          handleClose={() => setShowFolderPathSelectionModal(false)}
          handleSubmit={handleFolderPathSelected}
          submitButtonText="Add files"
          isSelectable
          modalTitle={folderPathModalTitle}
        />
      )}
    </>
  );
}
