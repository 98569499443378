'use client';

import { loadFile } from '@unique/next-commons/helpers';
import { ClientContext, Service } from '@unique/next-commons/swr';
import cn from 'classnames';
import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { MessagesQuery, MessageUpdateInput, Role, SortOrder } from '@/@generated/graphql';
import { Modal, ModalProps, Spinner } from '@unique/component-library';
import { IconThumbsDown, IconThumbsUp } from '@unique/icons';

import { removeSystemPrefixFromMessages } from '@/helpers/messages';
import { messagesUpdateResolver } from '@/lib/cache/messages-update-resolver';
import {
  getUseMessagesQueryKey,
  useMessagesQuery,
  useMessagesUpdateSubscription,
  useMessageUpdateMutation,
} from '@/lib/swr/hooks';
import { Assistant, ContentById, Message } from '@/lib/swr/types';
import { useRoles } from '@unique/next-commons/authorization';
import { logger } from '@unique/next-commons/logger';
import { ScrollWrapperContext, ToastVariant, useToast } from '@unique/shared-library';
import { GraphQLError } from 'graphql';
import { useParams } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { FeedbackModal } from './FeedbackModal';
import MessageItem from './MessageItem';
import { SavePromptModal } from './SavePromptModal';
import { setChatImageUrls, useAppDispatch, useAppSelector, chatSlice } from '@/store';
import { extractUniqueContentIdsForImages } from '@/helpers/extractUniqueContentIdsForImages';
import { uniq } from 'lodash';
import ContentList from './ContentList';
import { serializeError } from 'serialize-error';

interface ChatMessagesProps {
  handleSelectPrompt: (prompt: string) => void;
  currentChatAssistant: Assistant;
  content: ContentById[] | null;
  handleMutateContent: () => void;
}

const log = logger.child({
  package: 'chat',
  namespace: 'components:chat:chat-messages',
});

export const ChatMessages: FC<ChatMessagesProps> = ({
  handleSelectPrompt,
  currentChatAssistant,
  content,
  handleMutateContent,
}) => {
  const { id } = useParams<{ id: string }>();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<ModalProps | null>(null);
  const [updatedData, setUpdatedData] = useState(null);
  const auth = useAuth();
  const { showToast } = useToast();
  const dispatch = useAppDispatch();
  const { services } = useContext(ClientContext);
  const chatImageUrls = useAppSelector((state) => state.chat.chatImageUrls) ?? {};
  const chatId = typeof id === 'string' ? id : '';
  const streams = useAppSelector((state) => state.chat.streams);
  const isStreaming = useMemo(() => {
    return streams.some((stream) => stream.chatId === id);
  }, [streams]);

  const chatQueryVariables = {
    chatId,
    orderBy: [{ createdAt: SortOrder.Asc }],
  };

  const {
    data: messages,
    isLoading: isLoadingMessages,
    error: messagesError,
    mutate,
  } = useMessagesQuery(chatQueryVariables, {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });

  // Load images from internal storage if there are any in the messages
  // store them in the redux store so that they can be accessed later in the MessageItem component
  useEffect(() => {
    if (!messages?.messages?.length) return;
    const uniqueContentIds = uniq(
      messages.messages.flatMap((message) => extractUniqueContentIdsForImages(message.text)),
    );
    const loadImages = async () => {
      const promises = uniqueContentIds.map((contentId) =>
        loadFile({
          accessToken: auth.user.access_token,
          ingestionUrl: services[Service.NODE_INGESTION],
          content: {
            id: contentId,
            internallyStoredAt: new Date().toTimeString(),
          },
          chatId,
          shouldOpen: false,
        })
          .then((loadedFileUrl) => ({ [contentId]: loadedFileUrl }))
          .catch((err) => {
            log.error(`Error loading image ${err.toString()}`);
            return null; // Or handle errors as needed
          }),
      );
      Promise.all(promises).then((results) => {
        // Filter out any null results due to errors
        const chatImagesResults = results.filter((result) => result !== null);
        dispatch(
          setChatImageUrls({ ...chatImageUrls, ...Object.assign({}, ...chatImagesResults) }),
        );
      });
    };
    // only load images if there are unique content ids and they are not stored in redux yet
    const shouldLoadImages =
      uniqueContentIds.length > 0 && !uniqueContentIds.every((id) => chatImageUrls[id]);
    if (shouldLoadImages) {
      loadImages();
    }
  }, [messages, chatImageUrls, dispatch]);

  useEffect(() => {
    // We do not update data from WS if SWR is still loading
    if (!isLoadingMessages && updatedData) {
      const data = updatedData;

      // Update Redux state based on values in updatedData from WS
      if (data.messageUpdate.stoppedStreamingAt) {
        dispatch(
          chatSlice.actions.removeStream({
            chatId: data.messageUpdate.chatId,
            messageId: data.messageUpdate.id,
          }),
        );
      } else if (!isStreaming && data.messageUpdate.startedStreamingAt) {
        dispatch(
          chatSlice.actions.addStream({
            chatId: data.messageUpdate.chatId,
            messageId: data.messageUpdate.id,
          }),
        );
      }

      // The typecast to any is necessary here, because we abuse the original mutate and do not pass the
      // same data into the mutation as the original query!
      // !!Therefore we must ensure, that populateCache transforms the data correctly or we break the cache.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      mutate(data as any, {
        populateCache: messagesUpdateResolver,
        revalidate: false,
      });
      setUpdatedData(null);
    }
  }, [updatedData, isLoadingMessages, mutate]);

  const { showChunks } = useRoles();

  const { trigger: updateMessage } = useMessageUpdateMutation(
    getUseMessagesQueryKey(chatQueryVariables),
  );

  const { scrollToBottom, scrollToBottomCancelable } = useContext(ScrollWrapperContext);

  // Scroll to bottom when messages loaded
  useEffect(() => {
    if (!isLoadingMessages) {
      scrollToBottom();
    }
  }, [isLoadingMessages]);

  const subscriptionVariables = useMemo(() => ({ chatId }), [chatId]);

  const clientWs = useMessagesUpdateSubscription(
    {
      next: (data) => {
        // Each update is stored in a react state so it can we handled by the effect
        // avoiding a race condition with useSWR mutation. (See UN-5593)

        // If messageUpdate include stoppedStreamingAt, remove stream from redux before react handling
        if (data.messageUpdate.stoppedStreamingAt) {
          dispatch(
            chatSlice.actions.removeStream({
              chatId: data.messageUpdate.chatId,
              messageId: data.messageUpdate.id,
            }),
          );
        }

        setUpdatedData(data);
      },
      error: (errors: GraphQLError[]) => {
        log.error(`Message update subscription error. Error: ${JSON.stringify(errors)}`);
      },
      complete: () => {
        log.info('Message update subscription complete');
      },
    },
    subscriptionVariables,
  );

  useEffect(() => {
    // Client WS takes couple seconds to connect, and lose sync with previous call on useMessagesQuery.
    // Trigger an extra mutate to fetch new data. What about overriding mutate call from useMessagesUpdateSubscriptionin // ?
    const removeListener = clientWs.on('connected', () => {
      mutate();
      handleMutateContent();
    });
    return () => removeListener();
  }, [clientWs, mutate, handleMutateContent]);

  const handleModalClose = () => {
    setShowModal(false);
    setModalContent(null);
  };

  const updateMessageData = (messageId: string, input: MessageUpdateInput) => {
    const payload = {
      chatId,
      messageId,
      input,
    };
    updateMessage(payload, {
      revalidate: false,
      throwOnError: false,
      onError: (err) => {
        log(err);
      },
      optimisticData: (currentData) => {
        const data = currentData as unknown as MessagesQuery;
        data.messages.map((message) => {
          if (message.id === messageId) {
            message.feedback = {
              positive: !!input.feedback.create.positive,
              text: input.feedback.create.text,
              additionalInfo: input.feedback.create.additionalInfo,
            };
          }
          return message;
        });
        return { ...currentData, data };
      },
    });
  };

  const onThumbsClick = (messageId: string, isPositive?: boolean) => {
    const modalContent = {
      title: 'Provide additional feedback',
      icon: isPositive ? (
        <IconThumbsUp className="text-success-dark" height="26" width="24" />
      ) : (
        <IconThumbsDown className="text-error-dark mt-1" height="26" width="24" />
      ),
      children: (
        <FeedbackModal
          isPositive={isPositive}
          updateMessageData={updateMessageData}
          messageId={messageId}
          handleClose={handleModalClose}
        />
      ),
    };
    setModalContent(modalContent);
    setShowModal(true);
  };

  const onSavePromptClick = (prompt: string) => {
    const modalContent = {
      title: `Add to My Prompts in ${currentChatAssistant.name}`,
      children: (
        <SavePromptModal
          prompt={prompt}
          handleClose={handleModalClose}
          assistantId={currentChatAssistant.id}
        />
      ),
    };
    setModalContent(modalContent);
    setShowModal(true);
  };

  const onFileOpenClick = async (contentItem: ContentById) => {
    if (!contentItem) return;
    try {
      await loadFile({
        accessToken: auth.user.access_token,
        ingestionUrl: services[Service.NODE_INGESTION],
        content: contentItem,
        chatId,
        shouldOpen: true,
      });
    } catch (error) {
      log.error(
        { error: serializeError(error) },
        `Can not open file ${contentItem.title || contentItem.key}`,
      );
      showToast({
        message: `Can not open file ${contentItem.title || contentItem.key}`,
        variant: ToastVariant.ERROR,
      });
    }
  };

  const loadImageURLFromContent = async (contentItem: ContentById): Promise<string> => {
    if (!contentItem.mimeType.startsWith('image/') || !contentItem.id) return;
    return await loadFile({
      accessToken: auth.user.access_token,
      ingestionUrl: services[Service.NODE_INGESTION],
      content: contentItem,
      chatId,
      shouldOpen: false,
    });
  };

  const isMessage = (messageOrContent): messageOrContent is Message => {
    return messageOrContent?.role !== undefined;
  };

  const groupMessagesOrContent = (messagesOrContent: (Message | ContentById)[]) => {
    const result = messagesOrContent.reduce(
      (acc, item) => {
        if (isMessage(item)) {
          acc.push(item);
        } else {
          const lastItem = acc[acc.length - 1];
          if (lastItem && Array.isArray(lastItem) && !isMessage(lastItem[0])) {
            lastItem.push(item);
          } else {
            acc.push([item]);
          }
        }
        return acc;
      },
      [] as (Message | ContentById[])[],
    );
    return result;
  };

  const messagesOrContent = useMemo(() => {
    // only show finished or failed content
    const contentData = content || [];
    const messagesData = messages?.messages || [];

    const result = [...removeSystemPrefixFromMessages(messagesData), ...contentData].sort(
      (a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      },
    );

    // group content
    return groupMessagesOrContent(result);
  }, [content, messages]);

  /*
    Run scrollToBottomCancelable in useEffect instead of useMessagesUpdateSubscription next
    as it otherwise do not have updated state (isScrolling and isScrollingDisabled always false)
  */
  useEffect(() => {
    const lastItem = messagesOrContent[messagesOrContent.length - 1] as Message;
    const lastTextLength = lastItem?.text?.length;
    const lastOriginalTextLength = lastItem?.originalText?.length;
    if (lastItem?.role === Role.Assistant && lastTextLength === lastOriginalTextLength) return;
    scrollToBottomCancelable();
  }, [messagesOrContent]);

  if (isLoadingMessages)
    return (
      <Spinner wrapperClasses="absolute left-0 top-0 flex h-full w-full items-center justify-center" />
    );

  // TODO: Right now the PDF highlighting is behind a setting since it is not final yet.
  // Whats missing: We need to be able to show the PDF highlighting for external files as well.
  // Plus we need to improve the UI/UX for the PDF highlighting.
  const showPdfHighlighting = !!currentChatAssistant?.settings?.showPdfHighlighting;

  const redirectInternalStorageOnly =
    !!currentChatAssistant?.company?.configuration?.redirectInternalStorageOnly;

  return (
    <div className="mx-auto flex max-w-[928px] flex-1 flex-col items-start gap-2">
      {!messagesError &&
        messagesOrContent.map((item) => (
          <div
            className={cn({
              'text-on-background-main w-full px-0 sm:px-4': true,
              'mb-5 pt-7': isMessage(item),
              'mb-0 first:pt-4': !isMessage(item),
              'bg-surface text-on-surface': isMessage(item) && item.role !== Role.User,
            })}
            key={isMessage(item) ? item.id : item[0].id}
          >
            {isMessage(item) ? (
              <MessageItem
                message={item}
                showChunks={showChunks}
                onThumbsClick={onThumbsClick}
                onSavePromptClick={onSavePromptClick}
                handleSelectPrompt={handleSelectPrompt}
                showPdfHighlighting={showPdfHighlighting}
                redirectInternalStorageOnly={redirectInternalStorageOnly}
              />
            ) : (
              <ContentList
                content={item}
                handleFileOpenClick={onFileOpenClick}
                loadImageURLFromContent={loadImageURLFromContent}
              />
            )}
          </div>
        ))}

      {/* Modals */}
      <div
        tabIndex={-1}
        className={`pointer-events-none fixed z-50 opacity-0 transition-opacity ${
          showModal ? 'pointer-events-auto opacity-100' : ''
        }`}
      >
        {modalContent && (
          <Modal
            title={modalContent.title}
            icon={modalContent.icon}
            shouldShow={showModal}
            handleClose={handleModalClose}
          >
            {modalContent.children}
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ChatMessages;
