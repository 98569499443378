'use client';
import { FC, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { DefaultLayout, NetPromoterScoreProvider, OfflineHandler, TermsAndConditionsProvider } from '@unique/shared-library';
import { WithClientContextReady } from '@unique/next-commons/swr';
import { Navigation } from '../Navigation';

import { ConfigurationContext } from '@/providers/ConfigurationProvider';

export const Layout: FC = () => {
  const {
    selfUrl,
    chatUrl,
    knowledgeUploadUrl,
    adminUrl,
    basePath,
  } = useContext(ConfigurationContext);

  return (
    <DefaultLayout selfUrl={selfUrl} basePath={basePath}>
      <OfflineHandler />
      <Navigation
        chatUrl={chatUrl}
        knowledgeUploadUrl={knowledgeUploadUrl}
        adminUrl={adminUrl}
      />
      <WithClientContextReady>
        <TermsAndConditionsProvider>
          <NetPromoterScoreProvider>
            <Outlet />
          </NetPromoterScoreProvider>
        </TermsAndConditionsProvider>
      </WithClientContextReady>
    </DefaultLayout>
  );
};

export default Layout;
