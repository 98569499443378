'use client';
import { AssistantQuery, ChatQuery, MessageCreateInput, Role } from '@/@generated/graphql';
import { PoweredByDisclaimer } from '@/components/PoweredByDisclaimer';
import { SpaceInfo } from '@/components/Space/SpaceInfo';
import {
  BoxInputEnum,
  ButtonIcon,
  ButtonVariant,
  DRAWER_POSITION,
  InputSelectOption,
  usePrevious,
} from '@unique/component-library';
import { useContext, useEffect, useState } from 'react';
import TranslationBoxInput from './TranslationBoxInput';
import TranslationBoxView from './TranslationBoxView';
import { LayoutContext, ToastVariant, useScreens, useToast } from '@unique/shared-library';
import ChatHeader from '../Chat/ChatHeader';
import { SpaceHeader } from './SpaceHeader';
import { useNavigate } from 'react-router-dom';
import { supportedLanguages } from '@/helpers/translationTextHelpers';
import SelectDrawer from './SelectDrawer';
import { IconPaperPlane, IconChevronLeft } from '@unique/icons';
import TranslationFeedback from './TranslationFeedback';
import {
  setTextToTranslate,
  updateTranslateToLanguage,
  useAppDispatch,
  useAppSelector,
  setIsTranslating,
} from '@/store';
import useChatStream from '@/hooks/useChatStream';

type ChatAssistant = ChatQuery['chat']['assistant'];
type AssistantByUser = AssistantQuery['assistantByUser'];

type Props = {
  assistant: AssistantByUser | ChatAssistant;
  currentChat?: ChatQuery['chat'];
};

export type TranslationResult = {
  id: string;
  text: string;
  chatId: string;
  feedbackGiven: { positive: boolean } | null;
};

const DefaultResult = { id: '', text: '', chatId: '', feedbackGiven: null };

export default function TranslationComponent({ assistant, currentChat }: Props) {
  const navigate = useNavigate();
  const translateToLanguage = useAppSelector(({ translation }) => translation.translateToLanguage);
  const textToTranslate = useAppSelector(({ translation }) => translation.textToTranslate);
  const isTranslating = useAppSelector(({ translation }) => translation.isTranslating);

  const [text, setText] = useState(textToTranslate ?? '');
  const [result, setResult] = useState(DefaultResult);
  const [selectedOption, setSelectedOption] = useState<InputSelectOption>(translateToLanguage);
  const [showMobileTranslation, setShowMobileTranslation] = useState(false);

  const toast = useToast();

  const dispatch = useAppDispatch();
  const { isMobile } = useScreens();

  const { setHeaderItems } = useContext(LayoutContext);
  const previous = usePrevious(textToTranslate);

  const updateTextToTranslate = (text: string) => {
    dispatch(setTextToTranslate(text));
  };

  const { messages, handleChat, subscriptionData } = useChatStream({
    currentChatId: currentChat?.id,
    onError: () => {
      dispatch(setIsTranslating(false));
    },
  });

  const DefaultHeader = [
    <SpaceHeader key={`space-header-${assistant.id}`} title={assistant.name} />,
  ];

  const handleSelectOption = (selectedOption: InputSelectOption) => {
    dispatch(updateTranslateToLanguage(selectedOption));
    setSelectedOption(selectedOption);
    // If there is a current chat, update the translation
    if (result?.text || currentChat?.id) {
      setHeaderItems(DefaultHeader);
      setResult(DefaultResult);
      navigate(`/space/${assistant.id}`);
      // If there is a current chat, update the text to translate
      // to show the previous message in the input box.
      updateTextToTranslate(text);
    }
  };

  const clearTranslation = () => {
    if (currentChat) {
      navigate(`/space/${assistant.id}`);
    }
    setHeaderItems(DefaultHeader);
    setText('');
    setResult(DefaultResult);
    dispatch(updateTranslateToLanguage({ label: 'English', value: 'English' }));
  };

  useEffect(() => {
    if (!result.id && !currentChat?.id) return;
    setHeaderItems([
      <ChatHeader
        key={assistant.id}
        assistantTitle={assistant.title}
        customTitle={currentChat?.id ? '' : text}
        buttonText="New Translation"
        resetHeader={clearTranslation}
        drawerPosition={DRAWER_POSITION.TOP}
        onClickNewChat={() => {
          navigate(`/space/${assistant.id}`);
        }}
      />,
    ]);
  }, [setHeaderItems, result.text]);

  useEffect(() => {
    if (currentChat) return;
    setHeaderItems(DefaultHeader);
    return () => {
      setHeaderItems([]);
      // Clear the text to translate when the component is unmounted
      // And when a user moves from one chat to another
      updateTextToTranslate('');
    };
  }, [assistant, setHeaderItems, currentChat]);

  useEffect(() => {
    if (currentChat?.title) return;
    if (previous !== text && !text) {
      setResult(DefaultResult);
    }
  }, [text]);

  useEffect(() => {
    if (messages && !subscriptionData) {
      messages?.messages?.map((message) => {
        if (message.role === Role.Assistant) {
          setResult({
            id: message.id,
            chatId: message.chatId,
            text: message.originalText ?? message.text,
            feedbackGiven: { positive: message.feedback?.positive },
          });
        }
        if (message.role === Role.User) {
          setText(message.text);
          const initialLanguage = message?.translateToLanguage;
          setSelectedOption({
            label: initialLanguage,
            value: initialLanguage,
          });
        }
      });
      setShowMobileTranslation(true);
    }
  }, [messages]);

  useEffect(() => {
    if (!subscriptionData) return;
    dispatch(setIsTranslating(false));
    setResult({ ...result, text: subscriptionData?.messageUpdate?.text });
  }, [subscriptionData]);

  const isStreaming =
    !isTranslating &&
    subscriptionData &&
    subscriptionData.messageUpdate?.stoppedStreamingAt === null &&
    !subscriptionData.messageUpdate.text.startsWith('[SYSTEM]');

  const handleTranslate = () => {
    if (isStreaming || isTranslating) {
      toast.showToast({
        message: 'Please wait for the current translation to finish',
        variant: ToastVariant.ERROR,
      });
      return;
    }

    const payload: {
      input: MessageCreateInput;
      assistantId?: string | null;
    } = {
      input: {
        text,
        role: Role.User,
        translateToLanguage: selectedOption.value,
      },
      assistantId: assistant.id,
    };
    dispatch(setIsTranslating(true));
    handleChat(payload);
  };

  function hasExplanation(data: ChatAssistant): data is AssistantByUser {
    return 'explanation' in data;
  }

  function hasAlert(data: ChatAssistant): data is AssistantByUser {
    return 'alert' in data;
  }

  const clearButtonClicked = () => {
    setResult({ ...result, text: '' });
  };

  return (
    <div className="mx-auto flex h-[calc(100vh-130px)] max-w-[1440px] flex-col sm:h-[calc(100vh-90px)]">
      {isMobile && result.text ? (
        <div className="border-b-background-variant border-b">
          <ButtonIcon
            variant={ButtonVariant.SHAPE}
            icon={<IconChevronLeft width="14px" height="14px" />}
            className="!hover:text-on-background-main !text-on-background-main text-[16px] font-extrabold"
            onClick={() => setShowMobileTranslation(!showMobileTranslation)}
          >
            {showMobileTranslation ? 'Translation' : 'Original Text'}
          </ButtonIcon>
        </div>
      ) : null}
      <div className="bg-surface mt-5 flex h-full w-full flex-col px-5 sm:mt-10 sm:px-10">
        {isMobile ? (
          <div className="flex h-full w-full">
            {showMobileTranslation || isTranslating ? (
              <TranslationBoxView
                isLoading={isTranslating}
                config={BoxInputEnum.IMMUTABLE}
                result={result}
                selectedOption={selectedOption}
                handleSelectOption={handleSelectOption}
              />
            ) : (
              <TranslationBoxInput
                config={BoxInputEnum.MUTABLE}
                text={text}
                setText={setText}
                handleTranslate={handleTranslate}
                isLoading={isTranslating}
                selectedOption={selectedOption}
                onClearButtonClicked={clearButtonClicked}
              />
            )}
          </div>
        ) : (
          <div className="flex h-full w-full gap-5 md:flex-row">
            <TranslationBoxInput
              config={BoxInputEnum.MUTABLE}
              text={text}
              setText={setText}
              handleTranslate={handleTranslate}
              isLoading={isTranslating || isStreaming}
              selectedOption={selectedOption}
              onClearButtonClicked={clearButtonClicked}
            />
            <TranslationBoxView
              isLoading={isTranslating}
              config={BoxInputEnum.IMMUTABLE}
              result={result}
              selectedOption={selectedOption}
              handleSelectOption={handleSelectOption}
              className={'hidden sm:flex'}
            />
          </div>
        )}
        <div className="bg-surface mt-auto flex w-full flex-col py-2">
          <div className="flex">
            {!currentChat?.id && !text && (
              <SpaceInfo
                explanation={
                  hasExplanation(assistant) && assistant?.explanation.length
                    ? assistant?.explanation
                    : ''
                }
                alert={hasAlert(assistant) && assistant?.alert.length ? assistant?.alert : ''}
              />
            )}
          </div>
          {isMobile && showMobileTranslation ? (
            <>
              {result.text && (
                <div className="mb-3 flex justify-center sm:hidden">
                  <TranslationFeedback result={result} />
                </div>
              )}
            </>
          ) : (
            <div className="md:none mb-4 mt-3 md:mt-0">
              <div className="flex w-full items-center justify-between gap-1">
                <div className="flex gap-4 sm:hidden">
                  <SelectDrawer
                    selectedOption={selectedOption}
                    options={supportedLanguages.map((lang) => {
                      return { label: lang, value: lang };
                    })}
                    handleSelectOption={(selectedOption) => setSelectedOption(selectedOption)}
                    title="Select Language"
                  />
                </div>
                <div className="block sm:hidden">
                  <ButtonIcon
                    disabled={isTranslating || !text || isStreaming}
                    icon={<IconPaperPlane height="16" width="16" />}
                    onClick={handleTranslate}
                  >
                    Translate
                  </ButtonIcon>
                </div>
              </div>
            </div>
          )}
          <PoweredByDisclaimer />
        </div>
      </div>
    </div>
  );
}
