'use client';
import { FC } from 'react';
import ChatContainer from './ChatContainer';
import { useParams } from 'react-router-dom';
import { useChatQuery } from '@/lib/swr/hooks';
import { hasTranslationUI } from '@unique/next-commons/helpers/hasTranslationUI';
import TranslationComponent from '../Space/TranslationComponent';

export const ChatMessagesApp: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data } = useChatQuery({
    chatId: id,
  });

  const currentChat = data?.chat;

  if (currentChat?.id && hasTranslationUI(currentChat?.assistant)) {
    return <TranslationComponent assistant={currentChat.assistant} currentChat={currentChat} />;
  }

  return (
    <div className="mx-auto flex h-[calc(100vh-119px)] flex-col px-4 sm:h-[calc(100vh-81px)]">
      <ChatContainer id={id} currentChat={currentChat} />
    </div>
  );
};
