import { Assistant, ChatAssistant } from '@/lib/swr/types';
import { UITypes } from '@unique/next-commons/helpers/userInterface';

export const getAcceptableFileTypes = (assistant: Assistant | ChatAssistant) => {
  const { settings, modules } = assistant || {};
  const allowUploadingImages = !!settings?.imageUpload || false;
  const isTranslationUI = settings?.userInterface === UITypes.TRANSLATION;

  if (isTranslationUI) {
    return 'DOCx, XLS, PPT';
  }

  const allowOnlyImages = modules?.every(
    (module) => module.name === 'ImageSummarizer' || module.name === 'ImageUpload',
  );

  if (allowOnlyImages) {
    return 'PNG, JPG';
  }

  if (!allowUploadingImages) {
    return 'PDF, DOCx, HTML, MD, TXT';
  }

  return 'PDF, DOCx, HTML, MD, TXT, PNG, JPG';
};
