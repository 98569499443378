const ROW_ACTIONS_HEADER = 'ACTIONS';

export const getColumns = (colTitles: string[], colWidths: string[]) => {
  return colTitles.map((colTitle, index) => {
    const id = colTitle.toLowerCase().replace(' ', '_');
    return {
      Header: () => (
        <div className="flex items-center gap-0.5 sm:gap-2">
          <span className="text-on-background-main flex max-w-[140px] cursor-pointer items-center truncate sm:max-w-none">
            <span className={colTitle === ROW_ACTIONS_HEADER ? 'sr-only' : ''}>{colTitle}</span>
          </span>
        </div>
      ),
      accessor: (data) => data[id],
      id: id,
      width: colWidths[index],
    };
  });
};
