'use client';
import {
  ContentUpsertByChatMutation,
  getSdk,
  ImportMagicTableColumnsMutation,
  MagicTableSheetQuery,
  OwnerType,
} from '@/@generated/graphql';
import { getUseContentByChatQueryKey, useContentUpsertByChatMutation } from '@/lib/swr/hooks';
import { ConfigurationContext } from '@/providers/ConfigurationProvider';
import { ButtonIcon, ButtonVariant, Dropzone, Spinner } from '@unique/component-library';
import { IconAddColumn } from '@unique/icons';
import { clientContextValues } from '@unique/next-commons/swr';
import {
  SUPPORTED_MIME_PDF_DOCS_CSV,
  ToastVariant,
  useFileUpload,
  useToast,
} from '@unique/shared-library';
import { useParams } from 'react-router-dom';
import { FC, forwardRef, useContext, useState } from 'react';
import { ErrorCode, FileRejection, FileWithPath } from 'react-dropzone';

type Props = {
  disabled: boolean;
};

const DueDiligenceInputUploadButton: FC = forwardRef<HTMLElement, Props>(({ disabled }, ref) => {
  const { showToast } = useToast();

  const maxFiles = 1;
  const { maxFileSize } = useContext(ConfigurationContext);
  // UPSERT CONTENT
  const { trigger: upsertContent } = useContentUpsertByChatMutation(
    getUseContentByChatQueryKey({
      chatId: '',
    }),
  );

  const { spaceSlug: sheetId } = useParams<{ spaceId: string; spaceSlug: string }>();

  const [isUploading, setIsUploading] = useState(false);
  let contentId = null;

  const handleChatUpload = async (
    acceptedFiles: FileWithPath[],
    fileRejections: FileRejection[],
  ) => {
    if (disabled || isUploading) {
      return;
    }

    if (fileRejections.length) {
      const code = fileRejections[0].errors[0].code;
      let message = `Unknown Error (${code})`;
      switch (code) {
        case ErrorCode.FileTooLarge:
          message = `File too large. The current limit is ${maxFileSize / 1024 / 1024} MB per file.`;
          break;
        case ErrorCode.FileInvalidType:
          message = 'Invalid File Type';
          break;
        case ErrorCode.FileTooSmall:
          message = 'File size is too small';
          break;
        case ErrorCode.TooManyFiles:
          message = `Too many files. You can upload a maximum of ${maxFiles} files at once.`;
          break;
      }
      showToast({ message, variant: ToastVariant.ERROR });
      return;
    }

    try {
      setIsUploading(true);
      // Fetch MagicTableSheet to get chatId
      const sdkDefault = getSdk(clientContextValues.defaultClient);
      const contentIngestionStateQuery: MagicTableSheetQuery = await sdkDefault.MagicTableSheet({
        where: {
          id: {
            equals: sheetId,
          },
        },
      });

      const { chatId } = contentIngestionStateQuery.magicTableSheet;
      // Upload file to chat
      await handleUpload(acceptedFiles, fileRejections, { newChatId: chatId });

      // Assign contentId to MagicTabble
      const importMagicTableColumnsMutation: ImportMagicTableColumnsMutation =
        await sdkDefault.ImportMagicTableColumns({
          sheetId: sheetId,
          contentId: contentId, // TODO GET CONTENT ID FROM UPLOAD
        });

      console.log(importMagicTableColumnsMutation);
    } catch (error) {
      console.error('Upload failed:', error);
      showToast({ message: 'Upload failed. Please try again.', variant: ToastVariant.ERROR });
      return;
    } finally {
      setIsUploading(false);
    }
  };

  // Upload File
  const { handleUpload } = useFileUpload<OwnerType>({
    setError: (error) => showToast({ message: error, variant: ToastVariant.ERROR }),
    chatOwnerType: OwnerType.Chat,
    upsertContent: async (extraArgument, options) => {
      const response: ContentUpsertByChatMutation = await upsertContent(extraArgument, options);
      if (response?.contentUpsertByChat?.id) {
        contentId = response.contentUpsertByChat.id;
      }
      return response;
    },
    ownerType: OwnerType.Chat,
    upsertContentAttributeName: 'contentUpsertByChat',
    storeInternally: true,
    maxFiles: maxFiles,
    maxFileSize: maxFileSize,
  });

  return (
    <Dropzone
      ref={ref}
      onDrop={handleChatUpload}
      onError={(e) => console.log(e)}
      accept={SUPPORTED_MIME_PDF_DOCS_CSV}
      maxFiles={maxFiles}
      maxSize={maxFileSize}
      additonalClassname="h-full pb-2"
    >
      <ButtonIcon
        disabled={isUploading || disabled}
        variant={ButtonVariant.SECONDARY}
        icon={isUploading ? <Spinner size={16} wrapperClasses="pt-1" /> : <IconAddColumn />}
      >
        Import Columns
      </ButtonIcon>
    </Dropzone>
  );
});

export default DueDiligenceInputUploadButton;
