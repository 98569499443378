import { IconLogo, IconNewWindow } from '@unique/icons';
import { ClientThemeContext } from '@unique/shared-library';
import cn from 'classnames';
import { FC, useContext } from 'react';
import { Assistant } from '@/lib/swr/types';

type PoweredByDisclaimerProps = {
  className?: string;
  currentChatAssistant?: Assistant;
};

export const PoweredByDisclaimer: FC<PoweredByDisclaimerProps> = ({ className = '', currentChatAssistant }) => {
  const { version } = useContext(ClientThemeContext);

  const getFinanceGptVersionText = () => {
    return `(v ${version ?? ''})`;
  };

  const isWebSearchAssistant = currentChatAssistant?.modules.some(module => module.name === 'WebSearch') || false;

  return (
    <p
      className={cn({
        'text-on-control-dimmed m-auto mt-2 flex w-full max-w-4xl items-center justify-center text-center flex-col sm:flex-row':
          true,
        [className]: true,
      })}
    >
      <span className="flex items-center">
        <span className="text-unique my-auto mr-2">Powered by</span>
        <IconLogo width="60" className="my-auto h-3" />
        {version && <span className="body-2 mb-0.5 ml-2 text-xs">{getFinanceGptVersionText()}</span>}
      </span>
      {isWebSearchAssistant && (
        <span className="body-2 mb-0.5 mt-2 sm:mt-0 sm:ml-2 text-xs flex items-center">
          &middot; <a className="text-primary-cta cursor-pointer px-2 flex gap-1 items-center" href="https://www.microsoft.com/en-gb/privacy/privacystatement" target="_blank">
          Microsoft Privacy Statement <IconNewWindow width="12" height="12" />
          </a>
        </span>
      )}
    </p>
  );
};
